import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import Cookies from 'js-cookie'
import { has } from 'lodash'
import classnames from 'classnames'
import 'strophe.js'
import { Buffer } from 'buffer'

import styles from './App.module.scss'

import {
	InstanceResponseInterface,
	ModuleNamesInterface,
	StoreInterface
} from './utils/interfaces'
import PrivateRoute from './PrivateRoute'
import Notification from './components/common/Notification'
import CriticalError from './components/common/CriticalError'
import ConversionHandler from './components/ConversionHandler'
import TermsConditionsModal from './components/TermsConditionsModal'
import SlideshowReveal from './components/SlideshowReveal'
import FilePreview from './components/Card/components/FilePreview'
import PreloadTagsBackground from './components/PreloadTagsBackground'
import PageIncludes from './components/PageIncludes'
import Login from './pages/Login'
import Logout from './pages/Layout/components/Logout'
import ForgotPassword from './pages/ForgotPassword'
import SecondForgotPassword from './pages/ForgotPassword/secondStep'
import SsoMobileConfirm from './pages/SsoMobileConfirm'
import UserInvitation from './pages/UserInvitation'
import StoryBoard from './pages/StoryBoard'
import UserSettings from './pages/UserSettings'
import Layout from './pages/Layout'
import Analytics from './pages/Analytics'
import MobileVerificationCode from './pages/MobileVerificationCode'
import Offers from './pages/Offers'
import MyMemosites from './pages/MyMemosites'
import { axiosInstance, changeAuthObject } from './utils/requests'
import { sendAnalyticsBatch } from './api/requests/analytics'
import { clearAnalyticsBatch } from './store/actions/analytics'
import { getConfig } from './api/requests/config'
import { setTempValue } from './store/actions/temp'
import {
	authorizationCustomEndpoint,
	getUserInstancesEndpoint,
	openfireLoginEndpoint
} from './api/apiEndpoints_new'
import LogoutAndBackInModal from './components/LogoutAndBackInMolal'
import AutomatedTasks from './components/AutomatedTasks'
import Redirect from './components/Redirect'
import getAdminPath from './utils/helpers/getAdminPath'
import { getLayout } from './store/actions/layout'
import { getUser } from './api/requests/user'
import { setInstances, setUser } from './store/actions/authUser'
import CollaboraEditor from './components/CollaboraEditor'
import SplitPresentationHandler from './components/SplitPresentationHandler'
import LibraryHomepage from './pages/Library'
import StarredFiles from './pages/StarredFiles'
import MyFiles from './pages/MyFiles'
import { getTags } from './store/actions/tags'
import GettingStartedGuide from './components/GettingStartedGuide'
import FullScreenLoader from './components/common/FullScreenLoader'
import { BUILD } from './utils/consts'
import { publish } from './utils/helpers/event'
import XMPPMessageHandler from './components/XMPPMessageHandler'
import UploadManagerModal from './components/UploadManagerModal'
import MemositeSettingsModal from './components/MemositeSettingsModal'
import PostMessageHandler from './components/PostMessageHandler'
import GeneratingPdfWidget from './components/GeneratingPdfWidget'
import MyPresentations from './pages/MyPresentations'
import { getPresentationDeckCache } from './api/requests/presentations'
import { setPresentationBarSlides } from './store/actions/presentation'
import DashboardNew from './pages/DashboardNew'
import { checkRedirectParamInLocalStorage } from './utils/helpers/redirects'

enum RouteParamInterface {
	'login',
	'forgot-password',
	'forgot-password-step2',
	'login-new'
}

const App = () => {
	const cookies = Cookies.get()
	const dispatch = useDispatch()
	const onMessageHandler = React.useRef<null | Strophe.Handler>(null)
	const reduxStore = useStore()
	const [gettingStartedVisible, toggleGettingStartedVisible] = useState(false)
	const [loadingRefreshToken, setLoadingRefreshToken] = useState(true)
	const { user, instances } = useSelector(
		(store: StoreInterface) => store.authUser
	)
	const {
		collaboraData,
		currentModule,
		uploadManagerModal,
		generatePdfWidget
	} = useSelector((store: StoreInterface) => store.temp)

	const { settingsModalVisible } = useSelector(
		(store: StoreInterface) => store.memositeBuilder
	)

	const handleLogout = () => {
		Cookies.remove('JWT')
		Cookies.remove('KMSI')
		localStorage.clear()
		window.location.href = '/login'
	}

	/**
	 * @param authToken
	 * @param refreshToken
	 * @param setLoader
	 */
	async function refreshAccessToken(
		authToken: any,
		refreshToken: any,
		setLoader = false
	) {
		if (authToken) {
			if (setLoader) {
				setLoadingRefreshToken(true)
			}
			try {
				const response = await axiosInstance.get(authorizationCustomEndpoint, {
					params: {
						refresh_token: refreshToken
					},
					headers: {
						Authorization: `Bearer ${authToken}`
					}
				})
				if (response.data) {
					const res: any = response.data
					dispatch(
						changeAuthObject({
							accessToken: res.token,
							refreshToken: res.refresh_token
						})
					)
					if (res.authSuccess !== false) {
						await getUser(res.user?.id, res.token).then((userRes) => {
							const userObject = {
								...res,
								authSuccess: true,
								user: {
									...userRes,
									userGroupId: res.user.group_id,
									role: res.user.role
								}
							}
							dispatch(setUser(userObject))
							axiosInstance
								.get<InstanceResponseInterface[]>(getUserInstancesEndpoint, {
									headers: {
										Authorization: `Bearer ${authToken}`
									}
								})
								.then((instancesResp) => {
									dispatch(setInstances(instancesResp.data))
								})
						})
					}
					if (process.env.REACT_APP_ENV !== 'production') {
						Cookies.set('JWT', res.token, {
							secure: true,
							sameSite: 'None',
							expires: 365
						})
						Cookies.set('KMSI', res.refresh_token, {
							secure: true,
							sameSite: 'None',
							expires: 365
						})
					}
				}
				if (setLoader) {
					setLoadingRefreshToken(false)
				}
			} catch {
				if (setLoader) {
					setLoadingRefreshToken(false)
				}
				handleLogout()
			}
		}
	}

	const handleOnActive = () => {
		const hours = getTotalIdleTime() / 1000 / 60 / 60
		if (hours > 72) {
			handleLogout()
		} else if (hours >= 8 && hours < 72) {
			refreshAccessToken(user.token, user.refresh_token)
		}
	}

	const { getTotalIdleTime } = useIdleTimer({
		timeout: 1000 * 60 * 60 * 8,
		onActive: handleOnActive,
		debounce: 500
	})

	const hasValidCookies = useCallback(
		() => has(cookies, 'JWT') && has(cookies, 'KMSI'),
		[cookies]
	)

	const checkSource = (route: RouteParamInterface) => {
		switch (route) {
			case 0:
				return <Login />
			case 1:
				return <ForgotPassword />
			case 2:
				return <SecondForgotPassword />
		}
	}

	/**
	 *
	 * @description fetch config for the key print.to.pdf and store it to temp
	 * reducer
	 */
	const fetchPdfConfig = () => {
		try {
			getConfig('print.to.pdf').then(
				(response) =>
					response !== null &&
					dispatch(setTempValue('printToPdfEnabled', response.value))
			)
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 *
	 * @description fetch config for the key pdf.optimized and store it to temp
	 * reducer
	 */
	const fetchOptimizePdfConfig = () => {
		try {
			getConfig('pdf.optimized').then(
				(response) =>
					response !== null &&
					dispatch(setTempValue('pdfOptimized', response.value))
			)
		} catch (error) {
			console.error(error)
		}
	}

	/**
	 *
	 * @description fetch config for the key getting.started and store it to temp
	 * reducer
	 */
	const fetchGettingStartedModalConfig = () => {
		try {
			getConfig('getting.started').then(
				(response) => response === null && toggleGettingStartedVisible(true)
			)
		} catch (error) {
			console.error(error)
		}
	}

	const fetchCrmConfig = () => {
		try {
			getConfig('crm.enabled').then((response: any) => {
				try {
					if (response.value === 1) {
						getConfig('crm.settings').then((settingsResp: any) => {
							if (settingsResp && settingsResp.value) {
								dispatch(
									setTempValue('crmSettings', JSON.parse(settingsResp.value))
								)
							}
						})
					}
				} catch (error) {
					console.error(error)
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	const fetchInstanceName = () => {
		try {
			getConfig('instance.name').then((response: any) => {
				if (response) {
					dispatch(setTempValue('instanceName', response.value))
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	const fetchEmailBcc = () => {
		try {
			getConfig('email.bcc').then((response: any) => {
				if (response) {
					dispatch(setTempValue('emailBcc', response.value))
				} else {
					dispatch(setTempValue('emailBcc', ''))
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	const fetchDashboardDiscoverBlockConfig = () => {
		try {
			getConfig('dashboard.discover').then((response: any) => {
				if (response) {
					if (response.value === 1) {
						dispatch(setTempValue('dashboardDiscoverBlockVisible', true))
					}
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	const fetchPresentationDeckCache = () => {
		try {
			getPresentationDeckCache().then((response) => {
				if (response.slides && response.slides.length > 0) {
					dispatch(setPresentationBarSlides(response.slides))
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	const checkRedirectParamInUrl = () => {
		const urlParams = new URLSearchParams(window.location.search)
		const redirect = urlParams.get('redirect')
		if (redirect) {
			if (user?.user?.id) {
				window.location.href = `https://${redirect}`
			} else {
				window.localStorage.setItem('redirectParam', redirect)
			}
		}
	}

	useEffect(() => {
		setLoadingRefreshToken(false)
		if (user?.user?.id) {
			refreshAccessToken(user.token, user.refresh_token, true)
				.then(() => {
					dispatch(getLayout(user.token))
				})
				.catch((e) => console.error(e))
		} else {
			const authCookies = hasValidCookies()
			if (authCookies) {
				const { KMSI, JWT } = cookies
				refreshAccessToken(JWT, KMSI, true)
					.then(() => {
						dispatch(getLayout(user.token))
						checkRedirectParamInLocalStorage()
					})
					.catch((e) => console.error(e))
			}
		}
		checkRedirectParamInUrl()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getUserXMPPData = (userId: number) => {
		axiosInstance
			.get(`${openfireLoginEndpoint}/${userId}`, {
				headers: {
					Authorization: `Bearer ${user.token}`
				}
			})
			.then((response) => {
				const parts = response.data.token.split('.')
				const payload: any = JSON.parse(
					Buffer.from(parts[1], 'base64')?.toString()
				)
				const connection = new Strophe.Connection(
					`${process.env.REACT_APP_OPENFIRE_URL}/ws`
				)
				connectToXMPP(connection, response.data.xmppUser, payload.payload.op)
			})
	}

	const connectToXMPP = (
		connection: any,
		username: string,
		password: string
	) => {
		connection.connect(
			`${username}@openfire.salesfra.me`,
			password,
			(status: unknown) => {
				if (status === Strophe.Status.CONNECTED) {
					connection.send($pres().tree())
					onMessageHandler.current = connection.addHandler(
						(message: Element) => {
							const messageType = message.getAttribute('type')
							const messageBody = message.getElementsByTagName('body')
							if (messageType === 'chat') {
								if (messageBody[0].textContent) {
									const parsedMessage = JSON.parse(messageBody[0].textContent)
									publish(parsedMessage.type, parsedMessage)
								}
							}
							return true
						},
						'',
						'message'
					)
					dispatch(setTempValue('stropheConnection', connection))
				} else if (status === Strophe.Status.DISCONNECTED) {
					onMessageHandler.current = null
					connectToXMPP(connection, username, password)
				}
			}
		)
	}

	useEffect(() => {
		let analyticsInterval: any
		if (user.token && user?.user?.id) {
			if (
				process.env.REACT_APP_ENV === 'production' ||
				window.location.origin === 'https://app.salesframe.com'
			) {
				// @ts-ignore
				pendo.initialize({
					visitor: {
						id: user.user.id,
						email: user.user.email,
						full_name: `${user.user.firstname} ${user.user.lastname}`,
						role: user.user.role
					},

					account: {
						id: user.customerId,
						name: user.customerName
						// is_paying:    // Recommended if using Pendo Feedback
						// monthly_value:// Recommended if using Pendo Feedback
						// planLevel:    // Optional
						// planPrice:    // Optional
						// creationDate: // Optional
					}
				})
				// @ts-ignore
				window.initBrevo(user.user.email)
			}
			analyticsInterval = setInterval(() => {
				const analyticsBatch = reduxStore.getState().analytics.batch
				if (analyticsBatch.length > 0) {
					// only call the API if there's something to send
					sendAnalyticsBatch(analyticsBatch, user.token).then(() => {
						dispatch(clearAnalyticsBatch())
					})
				}
			}, 15000)
			getUserXMPPData(user?.user?.id)
		}
		return () => {
			clearInterval(analyticsInterval)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.user?.id])

	useEffect(() => {
		if (user?.user?.id) {
			try {
				if (instances.length > 0) {
					getConfig(`user.terms.${user?.user?.id}`).then((response: any) => {
						let termsAccepted = false
						let isNew = true
						if (response) {
							termsAccepted = response.value === 'true'
							isNew = false
						}
						if (!termsAccepted) {
							dispatch(setTempValue('showTermsModal', true))
						}
						dispatch(
							setTempValue('termsAccepted', {
								isNew,
								value: termsAccepted
							})
						)
					})
				}
			} catch (error) {
				console.error('no instances')
			}
			dispatch(getTags(user.token))
			fetchCrmConfig()
			fetchPdfConfig()
			fetchOptimizePdfConfig()
			fetchGettingStartedModalConfig()
			fetchInstanceName()
			fetchEmailBcc()
			fetchDashboardDiscoverBlockConfig()
			fetchPresentationDeckCache()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.user?.id, instances])

	return (
		<div
			className={classnames(
				currentModule !== ModuleNamesInterface.LAYOUT && styles.appWrapper
			)}
		>
			{!loadingRefreshToken && (
				<>
					{user.token && user.user && <PageIncludes />}
					<Routes>
						<Route path="/login" element={checkSource(0)} />
						<Route path="/logout" element={<Logout />} />
						<Route
							path="/mobile-verification-code"
							element={<MobileVerificationCode />}
						/>
						<Route
							path="/forgot-password"
							element={
								!user.token || !user.user ? (
									checkSource(1)
								) : (
									<Navigate to="/build" />
								)
							}
						/>
						<Route
							path="/forgot-password/:resetToken"
							element={
								!user.token || !user.user ? (
									checkSource(2)
								) : (
									<Navigate to="/build" />
								)
							}
						/>
						<Route path="/sso-mobile-confirm" element={<SsoMobileConfirm />} />
						<Route
							path="/user-invitation/:token"
							element={<UserInvitation />}
						/>
						<Route
							path="/"
							element={
								<PrivateRoute>
									<Navigate to="/build" />
								</PrivateRoute>
							}
						/>
						<Route
							path="/dashboard"
							element={
								<PrivateRoute>
									<Navigate to="/build" />
								</PrivateRoute>
							}
						/>
						<Route
							path="/build"
							element={
								<PrivateRoute>
									<DashboardNew />
								</PrivateRoute>
							}
						/>
						<Route
							path="/present*"
							element={
								<PrivateRoute>
									<StoryBoard />
								</PrivateRoute>
							}
						/>
						<Route
							path="/followup"
							element={
								<PrivateRoute>
									<MyMemosites />
								</PrivateRoute>
							}
						/>
						<Route
							path="/followup/:slug"
							element={
								<PrivateRoute>
									<MyMemosites />
								</PrivateRoute>
							}
						/>
						<Route
							path="/analytics"
							element={
								<PrivateRoute isManager>
									<Analytics />
								</PrivateRoute>
							}
						/>
						<Route
							path="/offers/:offerId"
							element={
								<PrivateRoute>
									<Offers />
								</PrivateRoute>
							}
						/>
						<Route
							path="/settings"
							element={
								<PrivateRoute>
									<UserSettings />
								</PrivateRoute>
							}
						/>
						<Route
							path="/admin"
							element={
								<Redirect
									location={getAdminPath(user.refresh_token, user.token)}
									target={'_self'}
								/>
							}
						/>
						<Route
							path="/layout"
							element={
								<PrivateRoute isAdmin>
									<Layout />
								</PrivateRoute>
							}
						/>
						<Route
							path="/library/:tagId?"
							element={
								<PrivateRoute>
									<LibraryHomepage />
								</PrivateRoute>
							}
						/>
						<Route
							path="/starred-files"
							element={
								<PrivateRoute>
									<StarredFiles />
								</PrivateRoute>
							}
						/>
						<Route
							path="/my-files"
							element={
								<PrivateRoute>
									<MyFiles />
								</PrivateRoute>
							}
						/>
						{/* all non-existing routes redirect to /build which will
						 redirect to /login if user isn't logged in */}
						<Route
							path="*"
							element={<Navigate to={`/${BUILD.toLowerCase()}`} />}
						/>
						<Route
							path="/my-presentations"
							element={
								<PrivateRoute>
									<MyPresentations />
								</PrivateRoute>
							}
						/>
						<Route
							path="/my-presentations/:slug"
							element={
								<PrivateRoute>
									<MyPresentations />
								</PrivateRoute>
							}
						/>
					</Routes>
					<Notification />
					<CriticalError />
					<ConversionHandler />
					{user.token && user.user && (
						<Fragment>
							<TermsConditionsModal />
							<FilePreview />
							<AutomatedTasks />
							<LogoutAndBackInModal />
							{collaboraData && <CollaboraEditor />}
							<SplitPresentationHandler />
							<GettingStartedGuide
								closeModal={() => toggleGettingStartedVisible(false)}
								isShowing={gettingStartedVisible}
							/>
							<XMPPMessageHandler />
							{uploadManagerModal.isShowing && <UploadManagerModal />}
							{settingsModalVisible && <MemositeSettingsModal />}
							{generatePdfWidget.visibility && <GeneratingPdfWidget />}
							<PostMessageHandler />
						</Fragment>
					)}
					<SlideshowReveal />
					<PreloadTagsBackground />
				</>
			)}
			<FullScreenLoader />
		</div>
	)
}

export default App
