import React, { useCallback } from 'react'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga'
import classnames from 'classnames'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import validator from 'validator'
import { toast } from 'react-toastify'
import { has } from 'lodash'
import { motion } from 'framer-motion'

import styles from './Login.module.scss'

import useLocalStorage from '../../utils/hooks/useLocalStorage'
import { ADMIN, DEFAULT, PASSWORD, SYSADMIN } from '../../utils/consts'
import {
	InstanceInterface,
	ModuleNamesInterface,
	StoreInterface
} from '../../utils/interfaces'
import salesframeLogoDark from '../../assets/logos/salesframe-logo-dark-800.png'
import salesframeLogoWhite from '../../assets/logos/salesframe-logo-white.svg'
import heroImage from './assets/new-to-salesframe-hero.png'
import Input from '../../components/common/Input'
import Button from '../../components/common/Button'
import { setLanguage, t } from '../../utils/languages/i18n'
import Spinner from '../../components/common/Spinner'
import MultiFactorAuthModal from './components/MultiFactorAuthModal'
import { axiosInstance, changeAuthObject } from '../../utils/requests'
import { checkUserEndpoint } from '../../api/apiEndpoints_new'
import {
	authUser,
	refreshAccessToken,
	removeUser,
	setInstances,
	setUser
} from '../../store/actions/authUser'
import { getUser, sendWelcomeEmail } from '../../api/requests/user'
import { getAllUserGroups, getInstances } from '../../store/actions/users'
import { getConfig } from '../../api/requests/config'
import { setTempValue } from '../../store/actions/temp'
import { getTags, getTagsBackgroundImages } from '../../store/actions/tags'
import { getLayout } from '../../store/actions/layout'
import { getAllMyFilesCategories } from '../../store/actions/myfiles'
import getSalesframeAPIUrl from '../../utils/getSalesframeAPIUrl'
import { checkRedirectParamInLocalStorage } from '../../utils/helpers/redirects'

const WEBSITE_URL = 'https://www.salesframe.com'
const TERMS_URL = 'https://www.salesframe.com/terms-of-service'
const PRIVACY_POLICY_URL = 'https://www.salesframe.com/privacy-policy'

const LoginNew = () => {
	ReactGA.initialize('UA-45846767-2')
	ReactGA.pageview(window.location.pathname + window.location.search)

	const passwordRef = React.useRef<HTMLInputElement>(
		document.createElement('input')
	)

	const cookies = Cookies.get()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [isSSOLoading, toggleIsSSOLoading] = useLocalStorage(
		'isSSOLoading',
		false
	)

	const [email, setEmail] = React.useState(
		process.env.NODE_ENV === 'development' &&
			process.env.REACT_APP_TEST_USERNAME
			? process.env.REACT_APP_TEST_USERNAME
			: ''
	)
	const [password, setPassword] = React.useState(
		process.env.NODE_ENV === 'development' &&
			process.env.REACT_APP_TEST_PASSWORD
			? process.env.REACT_APP_TEST_PASSWORD
			: ''
	)
	const [step, setStep] = React.useState(1)
	const [showLoader, setShowLoader] = React.useState(false)
	const [loaderCounter, setLoaderCounter] = React.useState(0)
	const [numOfTry, setNumOfTry] = React.useState<number | undefined>()
	const [captchaToken, setCaptchaToken] = React.useState('')
	const [mfaModalVisible, toggleMfaModalVisible] = React.useState(false)
	const [mfaVerifyData, setMfaVerifyData] = React.useState({
		GUID: '',
		UID: 0,
		signature: '',
		code: ''
	})
	const [selectedInstance, setSelectedInstance] = React.useState('')
	const [localSsoInstances, setLocalSsoInstances] = React.useState<
		InstanceInterface[]
	>([])
	const [localPassInstances, setLocalPassInstances] = React.useState<
		InstanceInterface[]
	>([])

	const isLoggedIn = useSelector(
		(store: StoreInterface) => store.authUser.user.authSuccess
	)
	const { instances } = useSelector((store: StoreInterface) => store.authUser)

	const hasValidCookies = useCallback(
		() => has(cookies, 'JWT') && has(cookies, 'KMSI'),
		[cookies]
	)

	React.useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.LOGIN))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		const timer =
			isSSOLoading &&
			setInterval(() => setLoaderCounter(loaderCounter + 1), 1000)
		if (loaderCounter > 6 && isSSOLoading) {
			toast(t('notifications.error.login'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: 'error',
				autoClose: 5000
			})
			toggleIsSSOLoading(false)
			setLoaderCounter(0)
		}
		return () => {
			toggleIsSSOLoading(false)
			clearInterval(timer)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSSOLoading, loaderCounter, navigate, toggleIsSSOLoading, captchaToken])

	React.useEffect(() => {
		const authCookies = hasValidCookies()
		const { KMSI, JWT } = cookies
		if (authCookies && !isLoggedIn && captchaToken) {
			setShowLoader(true)
			// now we need to login the user and dispatch the required actions
			const authObject = {
				token: JWT,
				refresh_token: KMSI
			}
			dispatch(
				changeAuthObject({
					accessToken: authObject.token,
					refreshToken: authObject.refresh_token
				})
			)
			refreshAccessToken(authObject.refresh_token, authObject.token)
				.then((user: any) => {
					// cookie stuff
					if (user.role === ADMIN) {
						// if the user is admin, fetch all the usergroups for Layout
						dispatch(getAllUserGroups(authObject.token))
					}
					// user is logged in at this point, so we can log them in
					Promise.all([
						dispatch(getTags(authObject.token)),
						dispatch(getLayout(authObject.token)),
						dispatch(getInstances(user.userEmail, captchaToken))
					])
						.then(() => {
							toggleIsSSOLoading(false)
							setShowLoader(false)
							checkRedirectParamInLocalStorage()
							navigate('/')
						})
						.catch(() => {
							setShowLoader(false)
						})
				})
				.catch(() => {
					setShowLoader(false)
					handleLogout()
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cookies, hasValidCookies, captchaToken])

	const handleLogout = () => {
		Cookies.remove('JWT')
		Cookies.remove('KMSI')
		localStorage.clear()
		window.location.href = '/login'
	}

	const handleNavigation = (e: React.FormEvent) => {
		e.preventDefault()
		e.stopPropagation()
		if (step === 1) {
			goToNextLoginState(1)
		} else {
			if (password.length > 0 && selectedInstance) handleLogin()
		}
	}

	const goToNextLoginState = (direction: number) => {
		if (direction === 1) {
			setCaptchaToken('')
			if (validator.isEmail(email)) {
				toast.dismiss()
				axiosInstance
					.get(`${checkUserEndpoint}/${email}/${captchaToken}`)
					.then((response) => {
						const customer = response.data
						if (
							customer.instances.length === 0 &&
							customer.instances_reg.length === 0
						) {
							toast(t('notifications.error.userNotFound'), {
								position: toast.POSITION.BOTTOM_RIGHT,
								type: toast.TYPE.ERROR,
								autoClose: 5000
							})
						} else {
							if (
								customer.instances.length === 0 &&
								customer.instances_reg.length === 1
							) {
								// skip step 2 if there is only one 'password' type instance
								if (customer.instances_reg[0]) {
									passwordRef.current.focus()
									setStep(3)
									setSelectedInstance(
										customer.instances_reg[0].customers_id.toString()
									)
								}
							} else {
								setStep(2)
							}
							setLocalSsoInstances(customer.instances)
							setLocalPassInstances(customer.instances_reg)
							dispatch(
								setInstances([
									...customer.instances.map((i: InstanceInterface) => ({
										...i,
										access: 'sso'
									})),
									...customer.instances_reg.map((i: InstanceInterface) => ({
										...i,
										access: 'password'
									}))
								])
							)
						}
					})
					.catch((e) => {
						const error = e?.response?.data?.error
						if (error && error.includes('suspended')) {
							toast(t('notifications.error.account_suspended'), {
								position: toast.POSITION.BOTTOM_RIGHT,
								type: toast.TYPE.ERROR,
								autoClose: 5000
							})
						}
					})
			} else {
				toast(t('notifications.error.enter-valid-email'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'error',
					autoClose: 5000
				})
			}
		} else if (direction === -1) {
			setCaptchaToken('')
			setStep(1)
		}
	}

	const handleAuth = (res: any) => {
		dispatch(
			changeAuthObject({
				accessToken: res.token,
				refreshToken: res.refresh_token
			})
		)
		if (res.authSuccess !== false) {
			getUser(res.user?.id, res.token).then((userRes) => {
				const userObject = {
					...res,
					authSuccess: true,
					user: {
						...userRes,
						userGroupId: res.user.group_id,
						role: res.user.role
					}
				}
				setLanguage(userObject.user.language)
				dispatch(setUser(userObject))
				if (process.env.REACT_APP_ENV !== 'production') {
					Cookies.set('JWT', res.token, {
						secure: true,
						sameSite: 'None',
						expires: 365
					})
					Cookies.set('KMSI', res.refresh_token, {
						secure: true,
						sameSite: 'None',
						expires: 365
					})
				}
				checkRedirectParamInLocalStorage()
			})
		}

		if (res.token) {
			const authToken = res.token
			if (res.user.role === ADMIN) {
				dispatch(getAllUserGroups(authToken))
			}

			if (instances.length > 0) {
				getConfig(`user.terms.${res.user.id}`).then((response) => {
					let termsAccepted = false
					let isNew = true
					if (response) {
						termsAccepted = response.value === 'true'
						isNew = false
					}
					if (!termsAccepted) {
						dispatch(setTempValue('showTermsModal', true))
					}
					dispatch(
						setTempValue('termsAccepted', {
							isNew,
							value: termsAccepted
						})
					)
				})
			}

			Promise.all([
				sendWelcomeEmail(res.user.id),
				dispatch(getTags(authToken)),
				dispatch(getLayout(authToken)),
				dispatch(getAllMyFilesCategories()),
				dispatch(getTagsBackgroundImages())
			]).then(() => {
				toast.dismiss()
				setShowLoader(false)
				if (res.user.role === SYSADMIN) {
					navigate('/admin/users')
				} else {
					navigate('/')
				}
			})
		}
	}

	const handleLogin = () => {
		setShowLoader(true)
		authUser({ email, password, captchaToken, client: selectedInstance })
			.then((res: any) => {
				if (res.token) {
					handleAuth(res)
				} else if (res.signature) {
					const mfaVerifyDataObject = {
						GUID: res.GUID,
						UID: res.UID,
						signature: res.signature,
						code: ''
					}
					setMfaVerifyData(mfaVerifyDataObject)
					toggleMfaModalVisible(true)
				} else {
					setShowLoader(false)
					const numberOfTriesLeft = 5 - res.response.data.number_of_tries
					const error = res?.response?.data?.error
					const userSuspended = error && error.includes('suspended')
					setNumOfTry(numberOfTriesLeft)
					if (userSuspended || numberOfTriesLeft === 0) {
						toast(t('notifications.error.account_suspended'), {
							position: toast.POSITION.BOTTOM_RIGHT,
							type: toast.TYPE.ERROR,
							autoClose: 5000
						})
					} else {
						const text = `${t(
							'notifications.error.username-and-password-doesnt-match'
						)} ${
							res.response.data.number_of_tries
								? `${t(
										'notifications.error.you-have'
								  )} ${numberOfTriesLeft} ${t(
										'notifications.error.tries-left'
								  )}.`
								: ''
						}`
						toast(text, {
							position: toast.POSITION.BOTTOM_RIGHT,
							type: toast.TYPE.ERROR,
							autoClose: 5000
						})
					}
				}
				setCaptchaToken('')
			})
			.catch((e) => {
				console.error(e)
				setShowLoader(false)
			})
	}

	const handleSso = (chosenIstance: InstanceInterface) => {
		setCaptchaToken('')
		toggleIsSSOLoading(true)
		setShowLoader(true)
		dispatch(removeUser())
		Cookies.remove('JWT')
		Cookies.remove('KMSI')
		window.location.href = `${getSalesframeAPIUrl()}/oauth/${
			chosenIstance.customer_id
		}`
	}

	const closeMfaModalHandler = () => {
		toggleMfaModalVisible(false)
		setShowLoader(false)
		setCaptchaToken('')
		navigate('/')
	}

	const openWebsiteHandler = () => {
		window.open(WEBSITE_URL, '_blank')
	}

	return (
		<div className={styles.wrapper} id="login">
			<div className={styles.backgroundContainer} />
			<motion.div
				className={styles.backgroundContainerOverlay}
				animate={step !== 1 ? 'visible' : 'hidden'}
				variants={{
					visible: {
						opacity: 1,
						transition: {
							duration: 0.8
						}
					},
					hidden: {
						opacity: 0,
						transition: {
							duration: 0.8
						},
						zIndex: -1
					}
				}}
			/>
			{showLoader && (
				<div className={styles.ssoLoader}>
					<Spinner isLoading />
				</div>
			)}
			{!captchaToken && (
				<GoogleReCaptcha onVerify={(token) => setCaptchaToken(token)} />
			)}
			<motion.div
				initial="visible"
				animate={step === 1 ? 'visible' : 'hidden'}
				variants={{
					visible: {
						x: 0,
						opacity: 1,
						transition: {
							duration: 0.8
						}
					},
					hidden: {
						position: 'fixed',
						x: -2000,
						opacity: 0,
						transition: {
							duration: 0.8
						},
						zIndex: -1
					}
				}}
				transition={{
					type: 'spring',
					bounce: 0,
					velocity: 2,
					duration: 1
				}}
			>
				<div className={styles.splitContentWrapper}>
					<div className={styles.leftCol}>
						<div className={styles.boxWrapper}>
							<div className={styles.logo}>
								<img src={salesframeLogoDark} alt="salesframe" />
							</div>
							<form onSubmit={(event) => handleNavigation(event)}>
								<div className={styles.box}>
									<div>
										<div className={styles.loginLabel}>
											{t('buttons.log-in-to-salesframe')}
										</div>
										<h1>{t('labels.plase_enter_your_username_email')}</h1>
										<Input
											label={null}
											onChange={(e) => setEmail(e.target.value)}
											name="username"
											initialValue={email}
											state={DEFAULT.toLowerCase()}
											placeholderText={t('input-placeholders.username_email')}
										/>
										<Button
											label={t('buttons.next')}
											buttonClass={styles.loginBtn}
											containerClass={styles.loginBtnContainer}
											isPositive
											buttonType="submit"
											id="login-next"
										/>
										<div className={styles.meta}>
											<ul>
												<li>
													<a href="/forgot-password">
														{t('misc.forgot-password')}
													</a>
												</li>
												<li>
													<p>
														{t('labels.read-our')}{' '}
														<a
															rel="noopener noreferrer"
															target="_blank"
															href={TERMS_URL}
														>
															{t('labels.terms-of-services')}
														</a>
														and{' '}
														<a
															rel="noopener noreferrer"
															target="_blank"
															href={PRIVACY_POLICY_URL}
														>
															{t('labels.privacy-policy')}
														</a>
													</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className={styles.storeIcons}>
									<div className={styles.google}>
										<a href="https://play.google.com/store/apps/details?id=me.salesfra.OfflineApp&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
											<img
												alt="Get it on Google Play"
												src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
											/>
										</a>
									</div>
									<div className={styles.apple}>
										<a href="https://apps.apple.com/us/app/salesframe-mobile/id1563556389?itsct=apps_box_badge&amp;itscg=30200">
											<img
												src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1627430400"
												alt="Download on the App Store"
											/>
										</a>
									</div>
									<div className={styles.microsoft}>
										<a
											href="https://apps.microsoft.com/detail/Salesframe%20Mobile/9P1V7LV3XV62?launch=true
	&mode=mini"
										>
											<img
												src="https://get.microsoft.com/images/en-us%20dark.svg"
												width="200"
												alt="Get it on Microsoft Store"
											/>
										</a>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className={styles.rightCol}>
						<div className={styles.content}>
							<h2>{t('titles.new_to_salesframe')}</h2>
							<img src={heroImage} alt="new to salesframe?" />
							<p>{t('misc.login_hero_text')}</p>
							<Button
								label={t('buttons.learn_more_on_our_website')}
								buttonClass={styles.loginBtn}
								containerClass={styles.loginBtnContainer}
								isPositive
								buttonType="button"
								onClick={openWebsiteHandler}
								id="login-learn-more"
							/>
						</div>
					</div>
				</div>
			</motion.div>
			<motion.div
				initial="visible"
				animate={step === 2 ? 'visible' : 'hidden'}
				variants={{
					visible: {
						x: 0,
						opacity: 1,
						transition: {
							duration: 0.8
						}
					},
					hidden: {
						position: 'fixed',
						x: -2000,
						opacity: 0,
						transition: {
							duration: 0.8
						},
						zIndex: -1
					}
				}}
				transition={{
					type: 'spring',
					bounce: 0,
					velocity: 2,
					duration: 1
				}}
			>
				<div className={styles.centeredContainer}>
					<div className={styles.content}>
						<div className={styles.logo}>
							<img src={salesframeLogoWhite} alt="salesframe" />
						</div>
						<div className={styles.instancesContainer}>
							{localSsoInstances.length > 0 && (
								<h1>{t('labels.log_in_with_sso')}</h1>
							)}
							{localSsoInstances.map((instance, index) => (
								<Button
									key={index}
									label={instance.name}
									onClick={() => handleSso(instance)}
									buttonClass={classnames(styles.loginBtn)}
									containerClass={styles.loginBtnContainer}
									isPositive
									id="login-sso"
								/>
							))}
							{localPassInstances.length > 0 && (
								<h1>{t('labels.log_in_with_a_pass')}</h1>
							)}
							{localPassInstances.map((instance, index) => (
								<Button
									key={index}
									label={instance.name}
									onClick={() => {
										setStep(3)
										passwordRef.current.focus()
										setSelectedInstance(instance.customers_id.toString())
									}}
									buttonClass={classnames(styles.loginBtn)}
									containerClass={styles.loginBtnContainer}
									isPositive
									id="login-pass"
								/>
							))}
						</div>
						<Button
							label={t('buttons.go_back')}
							onClick={() => goToNextLoginState(-1)}
							buttonClass={classnames(styles.loginBtn, styles.backBtn)}
							containerClass={styles.loginBtnContainer}
							id="login-back"
						/>
					</div>
				</div>
			</motion.div>
			<motion.div
				initial="visible"
				animate={step === 3 ? 'visible' : 'hidden'}
				variants={{
					visible: {
						x: 0,
						opacity: 1,
						transition: {
							duration: 0.8
						}
					},
					hidden: {
						position: 'fixed',
						x: -2000,
						opacity: 0,
						transition: {
							duration: 0.8
						},
						zIndex: -1
					}
				}}
				transition={{
					type: 'spring',
					bounce: 0,
					velocity: 2,
					duration: 1
				}}
			>
				<div className={styles.centeredContainer}>
					<div className={styles.content}>
						<div className={styles.logo}>
							<img src={salesframeLogoWhite} alt="salesframe" />
						</div>
						<form onSubmit={(event) => handleNavigation(event)}>
							<div className={styles.passwordContainer}>
								<h1>{t('labels.enter_your_password')}</h1>
								<Input
									onChange={(e) => setPassword(e.target.value)}
									name="password"
									wrapperClassName={styles.loginInput}
									type={PASSWORD.toLowerCase()}
									state={DEFAULT.toLowerCase()}
									label={null}
									initialValue={password}
									refProp={passwordRef}
									isDisabled={false}
								/>
								{numOfTry === 0 && (
									<div className={styles.lockedPassword}>
										<p>
											{t('labels.too-many-tries-account-locked')}{' '}
											<a href="/forgot-password">
												{t('labels.reset-your-password')}
											</a>
											,{t('labels.or-contact-your-admin')}
										</p>
									</div>
								)}
								<Button
									onClick={(e) => handleNavigation(e)}
									label={t('buttons.log-in')}
									buttonClass={classnames(styles.loginBtn)}
									containerClass={styles.loginBtnContainer}
									isPositive
									isDisabled={password.length === 0 || numOfTry === 0}
									buttonType="submit"
									id="login-submit"
								/>
								<Button
									label={t('buttons.go_back')}
									onClick={() => {
										setStep(
											localSsoInstances.length === 0 &&
												localPassInstances.length === 1
												? 1
												: 2
										)
										setNumOfTry(undefined)
										setPassword('')
									}}
									buttonClass={classnames(styles.loginBtn, styles.backBtn)}
									containerClass={styles.loginBtnContainer}
									id="login-go-back"
								/>
							</div>
						</form>
					</div>
				</div>
			</motion.div>
			<MultiFactorAuthModal
				cancelAction={closeMfaModalHandler}
				closeModal={() => toggleMfaModalVisible(false)}
				isShowing={mfaModalVisible}
				handleAuth={handleAuth}
				mfaVerifyDataObject={mfaVerifyData}
			/>
		</div>
	)
}

export default LoginNew
