import React from 'react'
import classnames from 'classnames'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import hexToRgba from 'hex-to-rgba'
import { FileUploadOutlined } from '@mui/icons-material'

import styles from './UploadCard.module.scss'

import { t } from '../../utils/languages/i18n'
import { StoreInterface } from '../../utils/interfaces'

const EmptyAreaWrapper = styled.div<{ presentationDeckFontColor: string }>`
	border: 2px dashed ${(props) => props.presentationDeckFontColor};
`

interface UploadCardInterface {
	onDrop: (files: any) => void
	toggleUploadManagerModalVisible: () => void
	useDeckFontColor?: boolean
	usedFromDeck?: boolean
	role: 'file-browser' | 'presentation-deck' | 'memosite-builder'
}

const UploadCard = ({
	onDrop,
	toggleUploadManagerModalVisible,
	useDeckFontColor = false,
	usedFromDeck = false,
	role
}: UploadCardInterface) => {
	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	const layoutSettings = useSelector((store: StoreInterface) => store.layout)

	return (
		<div
			{...getRootProps()}
			onClick={(e) => {
				e.stopPropagation()
				toggleUploadManagerModalVisible()
			}}
			id={`upload-card-add-content-${role}`}
		>
			<input {...getInputProps()} />
			<EmptyAreaWrapper
				className={classnames(
					styles.wrapper,
					!useDeckFontColor && styles.defaultStyle,
					usedFromDeck && styles.usedFromDeck
				)}
				presentationDeckFontColor={
					useDeckFontColor
						? layoutSettings.presentation &&
						  hexToRgba(
								layoutSettings.presentation.presentationDeckFontColor,
								1
						  )
						: '#FFFFFF'
				}
			>
				<div>{t('labels.drag_and_drop_materials_here_or_click')}</div>
				<div className={styles.button}>
					<FileUploadOutlined />
					<div>{t('labels.upload-content')}</div>
				</div>
			</EmptyAreaWrapper>
		</div>
	)
}

export default UploadCard
