import React, { useCallback } from 'react'
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import c from 'classnames'
import {
	ArrowBackOutlined as BackIcon,
	SettingsOutlined as SettingsIcon,
	PreviewOutlined as PreviewIcon,
	PublishOutlined as PublishIcon,
	Fullscreen as FullscreenIcon,
	DeleteOutlined as DeleteIcon,
	SystemUpdateAlt as ClosePreviewIcon,
	ChevronLeft as StepBackIcon,
	ChevronRight as StepForwardIcon,
	Info as InfoIcon
} from '@mui/icons-material'
import styled from 'styled-components'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { format, getTime } from 'date-fns'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { stateFromHTML } from 'draft-js-import-html'
import { toast } from 'react-toastify'
// @ts-ignore
import { arrayMoveByIndex } from 'array-move-multiple/index'
import { arrayMoveImmutable } from 'array-move'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { v4 as uuidv4 } from 'uuid'

import styles from './MemositeBar.module.scss'

import {
	MeetingInterface,
	ModuleNamesInterface,
	SlideInterface,
	StoreInterface,
	VideoGreetingInterface
} from '../../utils/interfaces'
import { t } from '../../utils/languages/i18n'
import {
	BUTTON_NORMAL,
	BUTTON_TEXT,
	HTML,
	ICON_LEFT,
	LG,
	SM
} from '../../utils/consts'
import Button from '../common/Button'
import { setTempValue } from '../../store/actions/temp'
import UploadCard from '../UploadCard'
import Card from '../Card'
import { useHorizontalScroll } from '../../utils/hooks/useHorizontalScroll'
import { layoutSettings as defaultLayout } from '../../utils/layoutSettings'
import Input from '../common/Input'
import isIOS from '../../utils/helpers/isIOS'
import isSafari from '../../utils/helpers/isSafari'
import {
	addToPresentation,
	clearPresentation,
	deleteById,
	reorderPresentationSlides,
	setPresentationBarSlides
} from '../../store/actions/presentation'
import handleFullScreen from '../../utils/helpers/handleFullscreen'
import AddGreetingCard from './components/AddGreetingCard'
import TextGreetingModal from './components/TextGreetingModal'
import AddVideoGreetingModal from './components/AddVideoGreetingModal'
import GreetingVideoRecorder from './components/VideoRecorder'
import PublishModal from './components/PublishModal'
import {
	excludeRestriectedFiles,
	getRestrictedFiles,
	removeUnnecessaryFieldsFromSlide
} from '../../utils/helpers/presentation'
import ConfirmDeleteModal from '../common/ConfirmDeleteModalNew'
import { getVideoGreetingsCategoryId } from '../../utils/helpers/myFiles'
import { uploadContent } from '../../api/requests/content'
import RestrictedFilesModal from './components/RestrictedFilesModal'
import { setMemositeBuilderValue } from '../../store/actions/memositeBuilder'
import { setMemositeValue } from '../../store/actions/memosite'
import {
	getMemositePreviewObject,
	updateMemosite
} from '../../api/requests/memosite'
import CRMIntegration from '../CRMIntegration'
import { subscribe, unsubscribe } from '../../utils/helpers/event'
import { getMemositeLink } from '../../utils/helpers/memosites'
import {
	isEditableDocument,
	isEditableSlide
} from '../../utils/helpers/isEditableSlide'
import getSalesframeAPIUrl from '../../utils/getSalesframeAPIUrl'
import { getPresentationDeckCache } from '../../api/requests/presentations'

const CardContainer = styled.div<{
	minMaxWidth: number
}>`
	grid-template-columns: repeat(
		auto-fill,
		minmax(${(props) => props.minMaxWidth}px, 1fr)
	);
`

const SortableWrapper = SortableContainer(
	({ children }: any, { divStyle, currentPresentationBarSize }) => (
		<div
			style={divStyle}
			className={c(
				styles.sortableContainer,
				styles[currentPresentationBarSize === LG ? LG.toLowerCase() : '']
			)}
		>
			{children}
		</div>
	)
)

const initialLength = {
	minutes: 0,
	seconds: 0
}

const SortableItem = SortableElement(({ children }: any) => (
	<div style={{ zIndex: 10000 }}>{children}</div>
))

const MemositeBar = ({
	editingMode,
	handleClose,
	isVisible
}: {
	editingMode: boolean
	handleClose: () => void
	isVisible: boolean
}) => {
	const dispatch = useDispatch()

	const frameRef = React.useRef<HTMLIFrameElement>(null)
	const previewMemositeData = React.useRef<any>()
	const previousRestrictedSlides = React.useRef<SlideInterface[]>([])

	const { layout, buildContext } = useSelector((store: StoreInterface) => store)
	const { presentationBarSize } = useSelector(
		(store: StoreInterface) => store.misc
	)
	const { conversionFiles, uploadManagerFiles, collaoraSavingFiles } =
		useSelector((store: StoreInterface) => store.temp)
	const { selectedMemosite } = useSelector(
		(store: StoreInterface) => store.memosite
	)
	const { status } = useSelector(
		(store: StoreInterface) => store.memositeBuilder
	)
	const { presentationBarSlides, loadedPresentation } = useSelector(
		(store: StoreInterface) => store.presentation
	)
	const { myFiles } = useSelector((store: StoreInterface) => store.myfiles)
	const authUser = useSelector(
		(reduxStore: StoreInterface) => reduxStore.authUser.user
	)
	const myFilesCategories = useSelector(
		(reduxStore: any) => reduxStore.myfiles.myFilesCategories
	)
	const { currentModule } = useSelector((store: StoreInterface) => store.temp)

	const [title, setTitle] = React.useState('')
	const [isDragging, toggleIsDragging] = React.useState(false)
	const [draggingCardIndex, setDraggingCardIndex] = React.useState<
		null | number
	>(null)
	const [selected, setSelected] = React.useState<any[]>([])
	const [textGreetingModalVisible, toggleTextGreetingModal] =
		React.useState(false)
	const [memositeTextGreeting, setMemositeTextGreeting] = React.useState(
		EditorState.createEmpty()
	)
	const [textGreetingAddedText, setTextGreetingAddedText] = React.useState('')
	const [textGreetingDeleteModalVisible, toggleTextGreetingDeleteModal] =
		React.useState(false)
	const [videoGreetingModalVisible, toggleVideoGreetingModal] =
		React.useState(false)
	const [isMediaRecordVisible, toggleMediaRecordVisible] = React.useState(false)
	const [isPublishModalVisible, togglePublishModalVisible] =
		React.useState(false)
	const [memositeForPublishing, setMemositeForPublishing] =
		React.useState<MeetingInterface>()
	const [greetingVideoLength, setGreetingVideoLength] =
		React.useState(initialLength)
	const [greetingVideoData, setGreetingVideoData] =
		React.useState<VideoGreetingInterface>()
	const [restrictedFiles, setRestrictedFiles] = React.useState<any>([])
	const [restrictedFilesModalVisible, toggleRestrictedFilesModalVisible] =
		React.useState(false)
	const [videoUploadPercent, setVideoUploadPercent] = React.useState(0)
	const [selectedMemositeObject, setSelectedMemositeObject] =
		React.useState<MeetingInterface>()
	const [previewModalVisible, togglePreviewModalVisible] =
		React.useState<any>(undefined)
	const [crmMemosite, setCrmMemosite] = React.useState<MeetingInterface | null>(
		null
	)
	const [editingMemositeUnsavedChanges, toggleEditingMemositeUnsavedChanges] =
		React.useState(false)
	const [unsavedChangesModalVisible, toggleUnsavedChangesModal] =
		React.useState(false)
	const [publishButtonDisabled, togglePublishButtonDisabled] =
		React.useState(false)
	const [errorPublishingVisible, toggleErrorPublishingVisible] =
		React.useState(false)

	const horizontalScrollRef = useHorizontalScroll(presentationBarSize)

	const [showLeftArrow, setShowLeftArrow] = React.useState(false)
	const [showRightArrow, setShowRightArrow] = React.useState(false)

	React.useEffect(() => {
		const container = horizontalScrollRef.current
		const updateArrows = () => {
			setShowLeftArrow(container.scrollLeft > 0)
			setShowRightArrow(
				container.scrollLeft < container.scrollWidth - container.clientWidth
			)
		}
		container.addEventListener('scroll', updateArrows)
		updateArrows()
		return () => {
			container.removeEventListener('scroll', updateArrows)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [presentationBarSlides])

	const scrollStepHandler = (scrollOffset: number) => {
		horizontalScrollRef.current.scrollLeft += scrollOffset
	}

	const divStyle = {
		background: layout.presentation
			? layout.presentation.presentationDeckColor
			: defaultLayout.presentationDeckColor
	}

	const handleMemositeMessage = () => {
		if (previewMemositeData.current && frameRef.current) {
			frameRef?.current.contentWindow?.postMessage(
				{ type: 'Memosite_Data', memosite: previewMemositeData.current },
				'*'
			)
		}
	}

	React.useEffect(() => {
		let supportedMimeType = ''
		if (navigator.userAgent.search('Firefox') > -1) {
			if (MediaRecorder.isTypeSupported('video/webm; codecs=vp8,opus'))
				supportedMimeType = 'video/webm; codecs=vp8,opus'
		} else {
			if (MediaRecorder.isTypeSupported('video/webm'))
				supportedMimeType = 'video/webm; codecs=vp9'
			else if (MediaRecorder.isTypeSupported('video/mp4'))
				supportedMimeType = 'video/mp4'
		}
		dispatch(setTempValue('supportedMimeType', supportedMimeType))
		if (editingMode) {
			subscribe('Memosite_Ready_Editing', handleMemositeMessage)
			return () => {
				unsubscribe('Memosite_Ready_Editing', handleMemositeMessage)
			}
		} else {
			subscribe('Memosite_Ready', handleMemositeMessage)
			return () => {
				unsubscribe('Memosite_Ready', handleMemositeMessage)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		if (editingMode && selectedMemosite) {
			setSelectedMemositeObject(selectedMemosite)
			setTitle(selectedMemosite.subject)
			const extendedFieldsParsed = JSON.parse(selectedMemosite.extended_fields)
			// setSlides(extendedFieldsParsed.summary)
			if (status === 'editing') {
				dispatch(clearPresentation())
				toggleEditingMemositeUnsavedChanges(true)
				dispatch(addToPresentation(extendedFieldsParsed.summary))
			}
			if (extendedFieldsParsed.videoGreeting) {
				const video = extendedFieldsParsed.videoGreeting
				setGreetingVideoData({
					...video,
					originalFileObject: {
						checksum: video.hash,
						name: video.name,
						type: 'webm'
					}
				})
				setGreetingVideoLength(
					extendedFieldsParsed.videoGreeting.greetingVideoLength
				)
			} else {
				setGreetingVideoData(undefined)
				setGreetingVideoLength(initialLength)
			}
			if (selectedMemosite.notes) {
				const notes = JSON.parse(selectedMemosite.notes).notes[0]
				if (notes) {
					try {
						const contentStateFromHtml = stateFromHTML(JSON.parse(notes))
						const contentState =
							EditorState.createWithContent(contentStateFromHtml)
						setMemositeTextGreeting(contentState)
						setTextGreetingAddedText(
							contentState.getCurrentContent().getPlainText()
						)
					} catch (e) {
						console.error('error parsing memosite notes:', e)
					}
				} else {
					setMemositeTextGreeting(EditorState.createEmpty())
					setTextGreetingAddedText('')
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMemosite, editingMode, status])

	/**
	 *
	 * @description when presentationBarSlides state changes, it checks if added
	 * file type is restricted. If not, it will be added to the state.
	 */
	React.useEffect(() => {
		if (!editingMode) {
			const restrictedFilesArray = getRestrictedFiles(presentationBarSlides)
			const allowedSlides = excludeRestriectedFiles(
				presentationBarSlides,
				restrictedFilesArray
			).map((slide, index) => ({
				...slide,
				index
			}))
			// setSlides(allowedSlides)
			// dispatch(addToPresentation(allowedSlides))
			if (
				presentationBarSlides > allowedSlides &&
				restrictedFilesArray > previousRestrictedSlides.current
			) {
				setRestrictedFiles(restrictedFilesArray)
				previousRestrictedSlides.current = restrictedFilesArray
				toggleRestrictedFilesModalVisible(true)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [presentationBarSlides])

	React.useEffect(() => {
		// reset all states to their defaults when loadedPresentation changes
		resetDataToDefaultValuesHandler()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadedPresentation])

	const closeRestrictedFilesModal = () => {
		toggleRestrictedFilesModalVisible(false)
	}

	const handleSelect = React.useCallback(
		(file, event) => {
			event.stopPropagation()
			event.preventDefault()
			const isAdded = selected.some(
				(currentFile: { key: number }) => currentFile.key === file.key
			)
			if (isAdded) {
				setSelected(
					selected.filter(
						(currentFile: { key: number }) => currentFile.key !== file.key
					)
				)
			} else {
				setSelected((currSelected) => [...currSelected, file])
			}
		},
		[selected]
	)

	const onDrop = useCallback(
		(files) => {
			dispatch(
				setTempValue('uploadManagerFiles', [...uploadManagerFiles, ...files])
			)
			dispatch(
				setTempValue('uploadManagerModal', {
					isShowing: true,
					role: status === 'editing' ? 'editing_memosite' : 'presentation_bar',
					modalType: 'modal'
				})
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[buildContext.selectedCategory, myFiles]
	)

	const greetingCardClickHandler = (type: string) => {
		if (type === 'text') {
			toggleTextGreetingModal(true)
		} else {
			toggleVideoGreetingModal(true)
		}
	}

	const fetchPresentationDeckCache = () => {
		try {
			getPresentationDeckCache().then((response) => {
				if (response.slides && response.slides.length > 0) {
					dispatch(setPresentationBarSlides(response.slides))
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	const closeHandler = (event?: React.MouseEvent) => {
		if (event) {
			event.stopPropagation()
		}
		dispatch(setMemositeBuilderValue('status', 'closed'))
		toggleUnsavedChangesModal(false)
		setSelected([])
		if (editingMode && selectedMemosite) {
			dispatch(setMemositeValue('selectedMemosite', undefined))
			setSelectedMemositeObject(undefined)
			toggleEditingMemositeUnsavedChanges(false)
			fetchPresentationDeckCache()
		}
		if (!editingMode) {
			// make presentationBar visible when memo builder is closed
			handleClose()
			// reset restricted files when user closes memo builder
			if (restrictedFiles.length > 0) {
				setRestrictedFiles([])
			}
		}
	}

	const uploadVideoGreeting = (
		length: { minutes: number; seconds: number },
		videoBlob: any
	) =>
		new Promise((resolve) => {
			if (!videoBlob) return resolve(false)
			const videoData = new FormData()
			const fileName =
				title === ''
					? `video-greeting-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}`
					: `${title.replace(/ /g, '-').toLowerCase()}`
			videoData.append('uploadedfiles', videoBlob, fileName)
			const videoGreetingCategoryId =
				getVideoGreetingsCategoryId(myFilesCategories)
			uploadContent(videoData, videoGreetingCategoryId, (percent: number) =>
				setVideoUploadPercent(percent < 100 ? percent : 0)
			).then((videoResult: any) => {
				const { checksum, name } = videoResult[0]
				resolve({
					filename: name,
					hash: checksum,
					greetingVideo: name,
					greetingVideoLength: length,
					originalFileObject: videoResult[0]
				})
			})
			return true
		})

	const uploadVideoGreetingHandler = async (videoBlob: any) => {
		togglePublishButtonDisabled(true)
		const greetingVideo: any = await uploadVideoGreeting(
			greetingVideoLength,
			videoBlob
		)
		setGreetingVideoData(greetingVideo)
		togglePublishButtonDisabled(false)
		if (editingMode) {
			toggleEditingMemositeUnsavedChanges(true)
		}
	}

	const uploadExistingVideoGreeting = (files: File[]) => {
		togglePublishButtonDisabled(true)
		uploadContent(
			files,
			getVideoGreetingsCategoryId(myFilesCategories),
			(percent: number) => setVideoUploadPercent(percent < 100 ? percent : 0),
			{ uploadType: 'videogreeting' }
		).then((result: any) => {
			const videoGreeting = {
				filename: result[0].name,
				hash: result[0].checksum,
				greetingVideo: result[0].name,
				greetingVideoLength: { minutes: 0, seconds: 0 },
				originalFileObject: result[0]
			}
			setGreetingVideoData(videoGreeting)
			togglePublishButtonDisabled(false)
			if (editingMode) {
				toggleEditingMemositeUnsavedChanges(true)
			}
		})
	}

	const prepareMemositeObjectForPublishing = () => {
		const newMemositeObject: any = {
			subject: title,
			notes: {
				actions: [],
				notes: textGreetingAddedText
					? [
							JSON.stringify(
								draftToHtml(
									convertToRaw(memositeTextGreeting.getCurrentContent())
								)
							),
							HTML.toLowerCase()
					  ]
					: []
			},
			extended_fields: {
				summary: presentationBarSlides.map((slideItem: any) =>
					removeUnnecessaryFieldsFromSlide(slideItem)
				),
				videoGreeting: greetingVideoData,
				expiryDate: false,
				userName: `${authUser.user.firstname} ${authUser.user.lastname}`,
				isXenonCreated: true
			},
			created: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
			id: getTime(new Date()).toString(),
			status: 10,
			slug: null,
			prospect_id: 1,
			starttime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
			endtime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
			secured_password: '',
			secured: false,
			greetings_draft: ''
		}
		return newMemositeObject
	}

	const resetDataToDefaultValuesHandler = () => {
		dispatch(setMemositeBuilderValue('status', 'closed'))
		setTitle('')
		setMemositeTextGreeting(EditorState.createEmpty())
		setTextGreetingAddedText('')
		setGreetingVideoLength(initialLength)
		setGreetingVideoData(undefined)
		// dispatch(setMemositeBuilderValue('publishedMemositeObject', undefined))
		setMemositeForPublishing(undefined)
		toggleErrorPublishingVisible(false)
	}

	const handleDeleteSelectedSlides = (isAll: boolean) => {
		if (isAll) {
			dispatch(clearPresentation())
			setSelected([])
		} else {
			const idToDelete = selected.map((tag) => ({ id: tag.id, key: tag.key }))
			dispatch(deleteById(idToDelete))
			setSelected([])
		}
	}

	const handleDeleteSlidesFromMemosite = () => {
		if (editingMode) {
			toggleEditingMemositeUnsavedChanges(true)
		}
		const idToDelete = selected.map((tag) => ({ id: tag.id, key: tag.key }))
		const filtered = presentationBarSlides.filter(
			(slide: any) =>
				!idToDelete.some(
					({ id, key }: any) => id === slide.id && key === slide.key
				)
		)
		// setSlides(filtered)
		dispatch(setPresentationBarSlides(filtered))
	}

	const updateMemositeHandler = () => {
		const extendedFieldsParsed =
			selectedMemositeObject &&
			JSON.parse(selectedMemositeObject.extended_fields)
		const updatedMemositeObject = {
			...selectedMemositeObject,
			subject: title,
			notes: JSON.stringify({
				actions: [],
				notes: textGreetingAddedText
					? [
							JSON.stringify(
								draftToHtml(
									convertToRaw(memositeTextGreeting.getCurrentContent())
								)
							),
							HTML.toLowerCase()
					  ]
					: []
			}),
			extended_fields: JSON.stringify({
				...extendedFieldsParsed,
				summary: presentationBarSlides.map((slideItem: any) =>
					removeUnnecessaryFieldsFromSlide(slideItem)
				),
				videoGreeting: greetingVideoData
			})
		}
		updateMemosite(updatedMemositeObject, authUser.token).then(() => {
			toast(t('notifications.success.updated-successfully'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: toast.TYPE.SUCCESS,
				autoClose: 5000
			})
			if (currentModule === ModuleNamesInterface.FOLLOW_UP) {
				dispatch(setTempValue('reloadMemosites', true))
			}
			closeHandler()
		})
	}

	const memositePublishDisabled = () => {
		if (publishButtonDisabled) {
			return true
		} else {
			if (title.length > 0) {
				if (
					presentationBarSlides.length > 0 ||
					greetingVideoData ||
					textGreetingAddedText.length > 0
				) {
					return false
				} else {
					return true
				}
			} else {
				return true
			}
		}
	}

	/**
	 *
	 * @description dispatches presentation slide reorder
	 * with the new sorted array
	 */
	const onSortEnd = ({
		oldIndex,
		newIndex
	}: {
		oldIndex: number
		newIndex: number
	}) => {
		let newItems
		if (selected.length > 1) {
			const indexesFromSelected: number[] = []
			selected.forEach((selectedItem) => {
				indexesFromSelected.push(presentationBarSlides.indexOf(selectedItem))
			})
			newItems = arrayMoveByIndex(
				presentationBarSlides,
				indexesFromSelected,
				oldIndex < newIndex ? newIndex - 1 : newIndex
			)
		} else {
			newItems = arrayMoveImmutable(presentationBarSlides, oldIndex, newIndex)
		}
		if (editingMode) {
			// setSlides(newItems)
			toggleEditingMemositeUnsavedChanges(true)
		}
		dispatch(reorderPresentationSlides(newItems))
		toggleIsDragging(false)
		setSelected([])
	}

	const memositePreviewHandler = () => {
		const memositeObjectPrepared = {
			subject: title,
			extended_fields: {
				summary: presentationBarSlides.map((slideItem: any) =>
					removeUnnecessaryFieldsFromSlide(slideItem)
				),
				videoGreeting: greetingVideoData,
				expiryDate: false,
				userName: `${authUser.user.firstname} ${authUser.user.lastname}`,
				isXenonCreated: true
			},
			owner_id: authUser.user.id,
			notes: {
				actions: [],
				notes: textGreetingAddedText
					? [
							JSON.stringify(
								draftToHtml(
									convertToRaw(memositeTextGreeting.getCurrentContent())
								)
							),
							HTML.toLowerCase()
					  ]
					: []
			}
		}
		getMemositePreviewObject(memositeObjectPrepared, authUser.token).then(
			(response) => {
				togglePreviewModalVisible(response)
				previewMemositeData.current = response
			}
		)
	}

	const renderRules = () => {
		if (title.length === 0) {
			if (
				presentationBarSlides.length > 0 ||
				greetingVideoData ||
				textGreetingAddedText.length > 0
			) {
				return t('misc.give-your-memosite-a-name')
			} else {
				return t('misc.add_some_content_and_give_a_name')
			}
		} else {
			if (
				presentationBarSlides.length > 0 ||
				greetingVideoData ||
				textGreetingAddedText.length > 0
			) {
				return ''
			} else {
				return t('misc.add_some_content')
			}
		}
	}

	const checkIfFileIsEditable = (file: any) => {
		if (file.type && isEditableDocument(file.type)) {
			if (file.status === 11) {
				return true
			}
			if (file.status === 1 && file.editing === 1) {
				return true
			}
		}
		if (file._file) {
			if (isEditableSlide(file._file.type) && file._file.splitted === 1) {
				if (file.status === 11) {
					return true
				}
				if (file.status === 1 && file._file.editing === 1) {
					return true
				}
			}
		}
		return false
	}

	const openCollabora = (file: any) => {
		const uuid = uuidv4()
		let url
		if (isEditableDocument(file.type)) {
			url = `${getSalesframeAPIUrl()}/wopi/files/${file.checksum}|${
				file.status === 1 ? 0 : file.tags_id
			}|${file.status}|${file.status}|${uuid}|${window.location.host}|v2`
		} else if (isEditableSlide(file._file.type)) {
			url = `${getSalesframeAPIUrl()}/wopi/files/${file._file.checksum}|${
				file.status === 1 ? 0 : file.tags_id
			}|${file._file.status}|${file.status}|${uuid}|${window.location.host}|v2`
		}
		if (url) {
			const collaboraObj = {
				url: `${window.location.origin}/editor/browser/6ba7057/cool.html?WOPISrc=${url}`,
				token: authUser.token,
				file,
				uuid,
				folderId: file.status === 1 ? 0 : file.tags_id,
				hideSaveButton: true,
				source: 'memosite_builder'
			}
			dispatch(setTempValue('collaboraData', collaboraObj))
		}
	}

	const isSavingFile = (file: any) => {
		const checksum = file._file ? file._file.checksum : file.checksum
		return (
			collaoraSavingFiles.findIndex(
				(item) => item.checksum === checksum && item.fileId === file.id
			) > -1
		)
	}

	const isFileSelected = (file: any) => {
		const slidesWithoutIndex: number[] = []
		selected.forEach((slide: any) => {
			slidesWithoutIndex.push(slide.id)
		})
		return slidesWithoutIndex.includes(file.id)
	}

	return (
		<motion.div
			initial="closed"
			animate={isVisible ? 'visible' : 'closed'}
			variants={{
				visible: {
					height: 271,
					opacity: 1,
					transition: {
						duration: 0.4
					}
				},
				closed: {
					height: 0,
					opacity: 0,
					transition: {
						duration: 0.4
					}
				}
			}}
			id={editingMode ? 'memosite-bar-editing' : 'memosite-bar'}
			className={styles.wrapper}
		>
			<div className={styles.actionsHeader}>
				<div
					className={c(styles.titleWrapper, editingMode && styles.editingMode)}
				>
					<Input
						placeholderText={t('misc.give-your-memosite-a-name')}
						name="title"
						initialValue={title}
						inputClassName={c(
							styles.input,
							errorPublishingVisible && styles.focused
						)}
						onChange={(e) => {
							setTitle(e.target.value)
							toggleErrorPublishingVisible(e.target.value.length === 0)
							if (editingMode && !editingMemositeUnsavedChanges) {
								toggleEditingMemositeUnsavedChanges(true)
							}
						}}
						wrapperClassName={styles.inputWrapper}
					/>
					<div className={styles.slidesCount}>
						<span className={styles.number}>
							{presentationBarSlides.length}
						</span>{' '}
						<span className={styles.label}>{t('labels.slides')}</span>
					</div>
				</div>
				{editingMode && (
					<div className={styles.editingMemosite}>
						{t('labels.editing_memosite')}
					</div>
				)}
				<div />
				<div className={styles.buttons}>
					<Button
						type={BUTTON_TEXT}
						containerClass={styles.btnTextWrapper}
						buttonClass={styles.btnText}
						iconSide={ICON_LEFT}
						icon={<BackIcon htmlColor="#FFFFFF" />}
						label={
							editingMode
								? t('buttons.close')
								: t('labels.back_to_presentation_deck')
						}
						onClick={(event) => {
							if (editingMode && editingMemositeUnsavedChanges) {
								toggleUnsavedChangesModal(true)
							} else {
								closeHandler(event)
							}
						}}
						id="memosite-bar-close-button"
					/>
					{editingMode && (
						<Button
							type={BUTTON_TEXT}
							containerClass={styles.btnTextWrapper}
							buttonClass={styles.btnText}
							iconSide={ICON_LEFT}
							icon={<SettingsIcon htmlColor="#FFFFFF" />}
							label={t('buttons.settings')}
							onClick={(event) => {
								event.stopPropagation()
								dispatch(setMemositeBuilderValue('settingsModalVisible', true))
								dispatch(
									setMemositeBuilderValue(
										'settingsModalData',
										selectedMemositeObject
									)
								)
							}}
							id="memosite-bar-settings-button"
						/>
					)}
					<Button
						type={BUTTON_TEXT}
						containerClass={styles.btnTextWrapper}
						buttonClass={styles.btnText}
						iconSide={ICON_LEFT}
						icon={<PreviewIcon htmlColor="#FFFFFF" />}
						label={t('buttons.preview')}
						onClick={memositePreviewHandler}
						id="memosite-bar-preview"
					/>
					<Button
						type={BUTTON_NORMAL}
						containerClass={styles.btnTextWrapper}
						buttonClass={c(styles.btnText, styles.btnPositive)}
						iconSide={ICON_LEFT}
						icon={<PublishIcon htmlColor="#FFFFFF" />}
						label={editingMode ? t('buttons.save-changes') : t('buttons.save')}
						onClick={(event) => {
							if (!memositePublishDisabled()) {
								event.stopPropagation()
								if (editingMode) {
									updateMemositeHandler()
								} else {
									setMemositeForPublishing(prepareMemositeObjectForPublishing())
									togglePublishModalVisible(true)
								}
							} else {
								if (title.length === 0) {
									toggleErrorPublishingVisible(true)
								}
								toast(renderRules(), {
									position: toast.POSITION.BOTTOM_RIGHT,
									type: toast.TYPE.ERROR,
									autoClose: 3000
								})
							}
						}}
						isPositive
						id="memosite-bar-publish"
					/>
				</div>
			</div>
			<div className={styles.actionsBody}>
				<div>
					<div>
						<Button
							type={BUTTON_TEXT}
							containerClass={styles.btnTextWrapper}
							buttonClass={c(styles.btnText, styles.removeFilesBtn)}
							iconSide={ICON_LEFT}
							icon={<DeleteIcon htmlColor="#FFFFFF" />}
							label={
								selected.length === 0
									? t('buttons.remove_all_files')
									: t('buttons.remove_files')
							}
							onClick={() =>
								editingMode
									? handleDeleteSlidesFromMemosite()
									: handleDeleteSelectedSlides(
											selected.length === presentationBarSlides.length ||
												selected.length === 0
									  )
							}
							isDisabled={
								selected.length === 0 && presentationBarSlides.length === 0
							}
							id="memosite-bar-remove-files"
						/>
					</div>
				</div>
				<div className={styles.buttons}>
					<Button
						containerClass={styles.btnTextWrapper}
						buttonClass={styles.btnText}
						type={BUTTON_TEXT}
						iconSide={ICON_LEFT}
						icon={<InfoIcon />}
						onClick={() => null}
						id="memosite-bar-featured-guide-button"
						tooltip={t('tooltips.quick_guide_for', [
							t('labels.memosite_builder')
						])}
					/>
					{!isIOS() && !isSafari() && (
						<Button
							type={BUTTON_TEXT}
							containerClass={styles.btnTextWrapper}
							buttonClass={styles.btnText}
							iconSide={ICON_LEFT}
							icon={<FullscreenIcon htmlColor="#FFFFFF" />}
							onClick={handleFullScreen}
							id="memosite-bar-fullscreen"
						/>
					)}
				</div>
			</div>
			<CardContainer
				minMaxWidth={130}
				className={c(styles.cardContainer, styles.smallContainer, styles.sm)}
				ref={horizontalScrollRef}
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				{showLeftArrow && (
					<div
						className={c(styles.scrollStep, styles.back)}
						onClick={() => scrollStepHandler(-500)}
					>
						<StepBackIcon />
					</div>
				)}
				{showRightArrow && (
					<div
						className={c(styles.scrollStep, styles.forward)}
						onClick={() => scrollStepHandler(500)}
					>
						<StepForwardIcon />
					</div>
				)}
				<AddGreetingCard
					type="video"
					action={greetingCardClickHandler}
					handleDelete={() => {
						setGreetingVideoData(undefined)
						setGreetingVideoLength(initialLength)
						if (editingMode) {
							toggleEditingMemositeUnsavedChanges(true)
						}
					}}
					handleEdit={() => toggleVideoGreetingModal(true)}
					addedVideo={
						greetingVideoData && {
							data: greetingVideoData,
							length: greetingVideoLength
						}
					}
					videoUploadPercent={videoUploadPercent}
				/>
				<AddGreetingCard
					type="text"
					action={greetingCardClickHandler}
					addedText={textGreetingAddedText}
					handleDelete={() => {
						toggleTextGreetingDeleteModal(true)
						if (editingMode) {
							toggleEditingMemositeUnsavedChanges(true)
						}
					}}
					handleEdit={greetingCardClickHandler}
				/>
				{presentationBarSize === SM && (
					<UploadCard
						toggleUploadManagerModalVisible={() =>
							dispatch(
								setTempValue('uploadManagerModal', {
									isShowing: true,
									role:
										status === 'editing'
											? 'editing_memosite'
											: 'presentation_bar',
									modalType: 'modal'
								})
							)
						}
						onDrop={onDrop}
						usedFromDeck={true}
						role="memosite-builder"
					/>
				)}
				<SortableWrapper
					axis="xy"
					distance={20}
					onSortEnd={onSortEnd}
					transitionDuration={400}
					updateBeforeSortStart={(node: { index: number }) => {
						setDraggingCardIndex(node.index)
						if (selected.length > 1) {
							toggleIsDragging(true)
						}
					}}
					divStyle={divStyle}
					currentPresentationBarSize={presentationBarSize}
					lockToContainerEdges
				>
					{presentationBarSize === LG && (
						<UploadCard
							toggleUploadManagerModalVisible={() =>
								dispatch(
									setTempValue('uploadManagerModal', {
										isShowing: true,
										role:
											status === 'editing'
												? 'editing_memosite'
												: 'presentation_bar',
										modalType: 'modal'
									})
								)
							}
							onDrop={onDrop}
							usedFromDeck={true}
							role="memosite-builder"
						/>
					)}
					{presentationBarSlides.map((file: any, index: number) => (
						<SortableItem
							/*key={`item-${file.key}`}*/
							key={`item-${file.key || `${file.id}-${file.tags_id}`}`}
							index={index}
							value={file.id}
						>
							<Card
								draggingFiles={
									(isDragging &&
										index === draggingCardIndex &&
										selected.length) ||
									undefined
								}
								key={file.key}
								hasToolbar
								className={
									selected.includes(file) && isDragging
										? styles.draggingCard
										: ''
								}
								isSelected={isFileSelected(file)}
								isToolbarVisible={selected[selected.length - 1] === file}
								handleCardClick={
									file.isLoading
										? () => null
										: (event) => handleSelect(file, event)
								}
								isLoadingCard={file.isLoading || isSavingFile(file) || false}
								isConverting={
									conversionFiles.findIndex(
										(item) =>
											item.checksum === file.checksum &&
											item.sortorder === file.sortorder
									) > -1
								}
								cardObject={{
									...file,
									extension: file.type,
									id: file.id
								}}
								hasLayoutFontColor={false}
								isEditable={checkIfFileIsEditable(file)}
								openFileEditor={openCollabora}
							/>
						</SortableItem>
					))}
				</SortableWrapper>
			</CardContainer>
			{textGreetingModalVisible && (
				<TextGreetingModal
					editGreeting={memositeTextGreeting}
					handleClose={() => toggleTextGreetingModal(false)}
					saveAndClose={(textGreeting, greetingPlainText) => {
						setMemositeTextGreeting(textGreeting)
						setTextGreetingAddedText(greetingPlainText)
						toggleTextGreetingModal(false)
						if (editingMode) {
							toggleEditingMemositeUnsavedChanges(true)
						}
					}}
				/>
			)}
			{videoGreetingModalVisible && (
				<AddVideoGreetingModal
					action={(type, files) => {
						if (type === 'record') {
							toggleVideoGreetingModal(false)
							toggleMediaRecordVisible(true)
						} else {
							if (files && files.length > 0) {
								toggleVideoGreetingModal(false)
								uploadExistingVideoGreeting(files)
							}
						}
					}}
					handleClose={() => toggleVideoGreetingModal(false)}
				/>
			)}
			<GreetingVideoRecorder
				handleClose={() => toggleMediaRecordVisible(false)}
				isVisible={isMediaRecordVisible}
				setVideoBlob={(videoBlob: any) => uploadVideoGreetingHandler(videoBlob)}
				setGreetingVideoLength={(length: any) => setGreetingVideoLength(length)}
			/>
			{memositeForPublishing && (
				<PublishModal
					isVisible={isPublishModalVisible}
					onClose={(restoreDefaults) => {
						if (currentModule === ModuleNamesInterface.FOLLOW_UP) {
							dispatch(setTempValue('reloadMemosites', true))
						}
						togglePublishModalVisible(false)
						if (restoreDefaults) {
							resetDataToDefaultValuesHandler()
						}
					}}
					memosite={memositeForPublishing}
					sendToCrm={setCrmMemosite}
				/>
			)}
			{crmMemosite && (
				<CRMIntegration memosite={crmMemosite} setMemosite={setCrmMemosite} />
			)}
			<ConfirmDeleteModal
				onApprove={() => {
					setTextGreetingAddedText('')
					setMemositeTextGreeting(EditorState.createEmpty())
					toggleTextGreetingDeleteModal(false)
				}}
				handleClose={() => toggleTextGreetingDeleteModal(false)}
				isActive={textGreetingDeleteModalVisible}
				confirmLabel={t('buttons.delete')}
				zIndex={10000}
				title={t('titles.are-you-sure')}
			>
				<div className={styles.deleteTextGreetingModalContent}>
					<p>{t('misc.are_you_sure_delete_text_greeting')}</p>
				</div>
			</ConfirmDeleteModal>
			<ConfirmDeleteModal
				onApprove={() => {
					updateMemositeHandler()
					toggleUnsavedChangesModal(false)
					toggleEditingMemositeUnsavedChanges(false)
				}}
				handleClose={() => closeHandler()}
				isActive={unsavedChangesModalVisible}
				confirmLabel={t('buttons.save-changes')}
				cancelLabel={t('buttons.cancel')}
				zIndex={10001}
				title={t('titles.unsaved-changes')}
			>
				<div className={styles.deleteTextGreetingModalContent}>
					<p>{t('misc.you_have_unsaved_changes_to_your_memosite')}</p>
				</div>
			</ConfirmDeleteModal>
			<RestrictedFilesModal
				isShowing={
					restrictedFilesModalVisible && ['opened', 'editing'].includes(status)
				}
				closeModal={closeRestrictedFilesModal}
				filesRestricted={restrictedFiles}
			/>
			{!!previewModalVisible && (
				<div
					className={styles.memositePreviewBackdrop}
					onClick={() => togglePreviewModalVisible(false)}
				>
					<div className={styles.exit}>
						<ClosePreviewIcon htmlColor="#FFFFFF" />
						{t('buttons.exit_preview')}
					</div>
					<iframe
						className={styles.previewIframe}
						ref={frameRef}
						title="Memosite"
						id="memosite-preview"
						name="memosite-preview"
						src={`${getMemositeLink(
							previewModalVisible.instanceName,
							'preview',
							authUser.user.role,
							true
						)}${
							window.location.origin === 'https://app.salesframe.com'
								? '&'
								: '?'
						}analytics=false&isEdit=${editingMode}`}
					/>
				</div>
			)}
		</motion.div>
	)
}

export default MemositeBar
