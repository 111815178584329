import React, { useState, useCallback, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import classnames from 'classnames'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import hexToRgba from 'hex-to-rgba'
import { differenceBy } from 'lodash'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import {
	AddBoxOutlined,
	SaveOutlined,
	Share as ShareIcon,
	DeleteOutlined as DeleteIcon,
	SaveOutlined as SaveOutlinedIcon,
	SaveAsOutlined as SaveAsOutlinedIcon,
	GroupOutlined as GroupOutlinedIcon,
	PictureAsPdfOutlined as PictureAsPDFOutlinedIcon,
	PlayCircleOutlined as PlayCircleOutlinedIcon,
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	PlayArrow as PlayIcon,
	Fullscreen as FullscreenIcon,
	LastPage as LastPageIcon,
	Poll as RiskMeterIcon,
	ChevronLeft as StepBackIcon,
	ChevronRight as StepForwardIcon,
	Info as InfoIcon
} from '@mui/icons-material'

//@ts-ignore
import { arrayMoveByIndex } from 'array-move-multiple/index'
import { v4 as uuidv4 } from 'uuid'
import useFingerprint from 'use-fingerprint'

import styles from './PresentationBar.module.scss'

import Button from '../common/Button'
import {
	BUTTON_TEXT,
	ICON_LEFT,
	LG,
	PRESENTATION_BAR,
	SM,
	SUCCESS,
	PDF_ALLOWED_TYPES,
	FLEX_START
} from '../../utils/consts'
import { t } from '../../utils/languages/i18n'
import {
	clearPresentation,
	clearSlideShow,
	createSlideShow,
	deleteById,
	reorderPresentationSlides,
	setLoadedPresentation,
	setLoadedPresentationDirty
} from '../../store/actions/presentation'
import Card from '../Card'
import {
	updatePresentation,
	updatePresentationDeckCache
} from '../../api/requests/presentations'
import SavePresentationModal from '../common/SavePresentationModal'
import { useHorizontalScroll } from '../../utils/hooks/useHorizontalScroll'
import { setPresentationbarSize } from '../../store/actions/misc'
import BigGreenButton from './components/BigGreenButton'
import CustomSlides from '../CustomSlides'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import ConfirmDeleteModal from '../common/ConfirmDeleteModal'
import Section from '../common/Section'
import { setTempValue } from '../../store/actions/temp'
import ShareWithColleagueModal from '../ShareWithColleagueModal'
import useOuterClick from '../../utils/hooks/useOuterClick'
import { createPdfNew } from '../../api/requests/pdf'
import { layoutSettings as defaultLayout } from '../../utils/layoutSettings'
import RiskMeterModal from './components/RiskMeterModal'
import { useEventListener } from '../../utils/hooks/useEventListener'
import { riskMeterCustomers } from '../../utils/helpers/customers'
import onSlideCreatedMessage from '../../utils/helpers/onMessage'
import RestrictedFilesInPdfModal from './components/RestrictedFilesInPdfModal'
import { setMemositeBuilderValue } from '../../store/actions/memositeBuilder'
import UploadCard from '../UploadCard'
import MemositeBar from '../MemositeBar'
import isSafari from '../../utils/helpers/isSafari'
import isIOS from '../../utils/helpers/isIOS'
import handleFullScreen from '../../utils/helpers/handleFullscreen'
import {
	isEditableDocument,
	isEditableSlide
} from '../../utils/helpers/isEditableSlide'
import { ModuleNamesInterface } from '../../utils/interfaces'
import { removeUnnecessaryFieldsFromSlide } from '../../utils/helpers/presentation'
import getSalesframeAPIUrl from '../../utils/getSalesframeAPIUrl'
import { setMyPresentationsValue } from '../../store/actions/myPresentations'

const presentationBarSizeButtonVariants = {
	sm: {
		rotate: -90
	},
	lg: {
		rotate: -270
	}
}

const PresentaitonBarRootContainer = styled.div`
	z-index: ${(props) => props.zIndex};
`

const PresentaitonBarInnerContainer = styled(motion.div)`
	background: ${(props) => props.presentationDeckColor};
	color: ${(props) => props.presentationDeckFontColor};
	border-color: ${(props) => props.presentationDeckFontColor};
`

const StyledActionButton = styled.button`
	background: ${(props) => hexToRgba(props.backgroundColor, 0.8)};
	color: ${(props) => hexToRgba(props.presentationDeckColor, 0.8)};

	&:hover {
		background: ${(props) => hexToRgba(props.presentationDeckFontColor, 1)};
		color: ${(props) => hexToRgba(props.presentationDeckColor, 1)};
		border-color: ${(props) => hexToRgba(props.presentationDeckFontColor, 1)};
	}
`

const StyledIconButton = styled(motion.button)`
	color: ${(props) => hexToRgba(props.presentationDeckFontColor, 0.8)};

	&:hover {
		color: ${(props) => hexToRgba(props.presentationDeckFontColor, 1)};
	}
`

const CardContainer = styled.div`
	grid-template-columns: repeat(
		auto-fill,
		minmax(${(props) => props.minMaxWidth}px, 1fr)
	);
`

const ScrollStepWrapper = styled.div`
	background: linear-gradient(
		${(props) => props.gradientDirection},
		${(props) => props.backgroundColor} 10%,
		transparent
	);
	color: ${(props) => props.fontColor};
`

const SortableWrapper = SortableContainer(({ children }, divStyle) => (
	<div style={divStyle} className={classnames(styles.sortableContainer)}>
		{children}
	</div>
))

const SortableItem = SortableElement(({ children }) => (
	<div style={{ zIndex: 10000 }}>{children}</div>
))

const PresentationBar = ({ autoCloseContentPane, closeContentPane }) => {
	const dispatch = useDispatch()
	const fingerPrint = useFingerprint()

	const buildContext = useSelector((state) => state.buildContext)
	const currentPresentationBarSize = useSelector(
		(state) => state.misc.presentationBarSize
	)
	const authToken = useSelector((store) => store.authUser.user.token)
	const selectedFiles = useSelector(
		(store) => store.presentation.presentationBarSlides
	)
	const myFiles = useSelector((store) => store.myfiles.myFiles)
	const layoutSettings = useSelector((state) => state.layout)
	const currentFiles = useSelector(
		(store) => store.presentation.presentationBarSlides
	)
	const presentationBarAnimationControls = useAnimation()
	const { loadedPresentation } = useSelector((state) => state.presentation)
	const {
		conversionFiles,
		printToPdfEnabled,
		splitFilesList,
		uploadManagerFiles,
		collaoraSavingFiles,
		currentModule,
		presentationBarVisible
	} = useSelector((store) => store.temp)
	const loggedInUserName =
		useSelector((state) => state?.authUser?.user?.user?.firstname) || undefined
	const customerId = useSelector((state) => state.authUser.user.customerId)
	const memositeBuilderStatus = useSelector(
		(state) => state.memositeBuilder.status
	)

	const [selected, setSelected] = useState([])
	const [isDropdownVisibe, toggleDropwdown] = useState(false)
	const [isSavePresentationModalOpen, toggleSavePresentationModal] =
		useState(false)
	const [presentationBarSize, togglePresentationBarSize] = useState(SM)
	const [openCustomSlides, toggleOpenCustomSlides] = useState(false)
	const [isConfirmPlayModalOpen, toggleConfirmPlayModalOpen] = useState(false)
	const [isShareAsDropdownVisible, toggleShareAsDropwdown] = useState(false)
	const [isShareWithColleagueModalVisible, toggleShareWithColleagueModal] =
		useState(false)
	const [newPresentationId, setNewPresentationId] = useState(undefined)
	const [isNewPresentation, toggleIsNewPresentation] = useState(false)
	const [riskMeterModal, toggleRiskMeterModal] = useState(false)
	const [restrictedFiles, setRestrictedFiles] = useState([])
	const [restrictedFilesModalVisible, toggleRestrictedFilesModalVisible] =
		useState(false)
	const [isNewPresentationConfirmVisible, toggleNewPresentationConfirmModal] =
		useState(false)
	const [isSaveDropdownVisible, toggleSaveDropdownVisible] = useState(false)
	const [isDragging, toggleIsDragging] = useState(false)
	const [draggingCardIndex, setDraggingCardIndex] = useState(null)

	const horizontalScrollRef = useHorizontalScroll(currentPresentationBarSize)

	const [showLeftArrow, setShowLeftArrow] = useState(false)
	const [showRightArrow, setShowRightArrow] = useState(false)

	React.useEffect(() => {
		const container = horizontalScrollRef.current
		const updateArrows = () => {
			setShowLeftArrow(container.scrollLeft > 0)
			setShowRightArrow(
				container.scrollLeft < container.scrollWidth - container.clientWidth
			)
		}
		container.addEventListener('scroll', updateArrows)
		updateArrows()
		return () => {
			container.removeEventListener('scroll', updateArrows)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFiles])

	const scrollStepHandler = (scrollOffset) => {
		horizontalScrollRef.current.scrollTo({
			left: horizontalScrollRef.current.scrollLeft + scrollOffset,
			behavior: 'smooth'
		})
	}

	/**
	 *
	 * @description reset selected state to an empty array if loadedPresentation
	 * has changed
	 */
	useEffect(() => {
		setSelected([])
	}, [loadedPresentation])

	/**
	 *
	 * @description listens to 'message' event sent from child iframes
	 */
	useEventListener('message', onSlideCreatedMessage, window)

	/**
	 *
	 * @description uses useOuterClick() custom hook which sets the click listener
	 * in order to close dropdown by clicking outside of it
	 */
	const shareDropdownRef = useOuterClick(() => {
		toggleShareAsDropwdown(false)
	})

	const saveAsDropdownRef = useOuterClick(() => {
		toggleSaveDropdownVisible(false)
	})

	/**
	 *
	 * @description dispatches presentation slide reorder
	 * with the new sorted array
	 **/
	const onSortEnd = ({ oldIndex, newIndex }) => {
		let newItems
		if (selected.length > 1) {
			let indexesFromSelected = []
			selected.forEach((selectedItem) => {
				indexesFromSelected.push(currentFiles.indexOf(selectedItem))
			})
			newItems = arrayMoveByIndex(
				currentFiles,
				indexesFromSelected,
				oldIndex < newIndex ? newIndex - 1 : newIndex
			)
		} else {
			newItems = arrayMoveImmutable(currentFiles, oldIndex, newIndex)
		}
		dispatch(reorderPresentationSlides(newItems))
		if (loadedPresentation) {
			dispatch(setLoadedPresentationDirty(true))
		}
		toggleIsDragging(false)
		setSelected([])
	}

	React.useEffect(() => {
		if (memositeBuilderStatus !== 'editing') {
			updatePresentationDeckCache(currentFiles)
		}
	}, [currentFiles])

	const divStyle = {
		background: layoutSettings.presentation
			? layoutSettings.presentation.presentationDeckColor
			: defaultLayout.presentationDeckColor
	}

	const animationVariants = (height) => ({
		openSm: {
			height
		},
		closed: {
			height: 60
		},
		openLg: {
			height: 'calc(90vh - 110px)'
		}
	})

	/**
	 *
	 * @callback useCallback which is a react hooks for handling callback functions
	 * @description the callback function becing called once files had been selected for upload
	 * in the user's native browser fileDialog
	 * adds the uploaded file to analytics batch
	 */
	const onDrop = useCallback(
		(files) => {
			dispatch(
				setTempValue('uploadManagerFiles', [...uploadManagerFiles, ...files])
			)
			dispatch(
				setTempValue('uploadManagerModal', {
					isShowing: true,
					role: 'presentation_bar',
					modalType: 'modal'
				})
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[buildContext.selectedCategory, myFiles]
	)

	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	/**
	 *
	 * @description moves the slides from the presentaiton bar to the presentation reducer
	 * @param {object} event the performed click's event object
	 * starts the presentation
	 */
	const handlePresentationStart = (event) => {
		event.stopPropagation()
		const hasLoadingCards = selectedFiles.filter((slide) => slide.isLoading)
		if (hasLoadingCards.length > 0) {
			toggleConfirmPlayModalOpen(true)
		} else {
			dispatch(clearSlideShow())
			dispatch(
				createSlideShow({
					slides: selectedFiles,
					startFrom: 0
				})
			)
		}
	}

	/**
	 *
	 */
	const handleOpen = () => {
		const newState = !presentationBarVisible
		dispatch(setTempValue('presentationBarVisible', newState))
		if (newState) {
			if (currentPresentationBarSize === SM) {
				presentationBarAnimationControls.start(animationVariants(270).openSm)
			} else if (currentPresentationBarSize === LG) {
				presentationBarAnimationControls.start(animationVariants().openLg)
			}
		} else {
			presentationBarAnimationControls.start(animationVariants().closed)
		}
		if (autoCloseContentPane) {
			closeContentPane()
		}
	}

	/**
	 *
	 * @description handles the select / unselect of card click
	 * @param {object} file the clicked slide object
	 * @param {object} event the event of the permormed click
	 */
	const handleSelect = useCallback(
		(file, event) => {
			event.stopPropagation()
			event.preventDefault()
			const isAdded = selected.some(
				(currentFile) => currentFile.key === file.key
			)
			if (isAdded) {
				setSelected(
					selected.filter((currentFile) => currentFile.key !== file.key)
				)
			} else {
				setSelected((currSelected) => [...currSelected, file])
			}
		},
		[selected]
	)

	/**
	 *
	 * @description toggles the Share As dropdown menu
	 * @param {object} event the event of the clicked performance
	 */
	const handleShareAsDropDown = (event) => {
		event.stopPropagation()
		toggleShareAsDropwdown(!isShareAsDropdownVisible)
	}

	/**
	 * @param {boolean} isAll whether all slides or selected ones needs to be deleted
	 * @description adds the currently selected items to the presentation
	 */
	const handleDeleteFromPresentationBar = (isAll) => {
		if (isAll) {
			dispatch(clearPresentation())
			setSelected([])
		} else {
			const idToDelte = selected.map((tag) => ({ id: tag.id, key: tag.key }))
			dispatch(deleteById(idToDelte))
			if (loadedPresentation) {
				dispatch(setLoadedPresentationDirty(true))
			}
			setSelected([])
		}
	}

	/**
	 *
	 * @description saves or updates a presentation
	 * @param {object} event the performed clicked event
	 * @param {boolean} updateExisting update or create new presentation
	 */
	const handlePresentationSave = (event, updateExisting) => {
		event.stopPropagation()
		if (loadedPresentation) {
			if (updateExisting) {
				updatePresentation(
					{
						id: loadedPresentation.id,
						subject: loadedPresentation.subject,
						description: loadedPresentation.description,
						notes: loadedPresentation.notes || null,
						location: loadedPresentation.location,
						starttime: loadedPresentation.starttime,
						endtime: loadedPresentation.endtime,
						status: loadedPresentation.status,
						external_owner_id: loadedPresentation.external_owner_id,
						extended_fields: JSON.stringify({
							summary: selectedFiles.map((slideItem) =>
								removeUnnecessaryFieldsFromSlide(slideItem)
							)
						}),
						prospect_id: loadedPresentation.prospect_id,
						owner_id: loadedPresentation.owner_id,
						creator: loadedPresentation.creator,
						modifier: loadedPresentation.modifier,
						created_at: new Date(loadedPresentation.created_at),
						updated_at: new Date(),
						send_notification: false,
						total: loadedPresentation.total,
						slides: loadedPresentation.slides
					},
					authToken
				).then((savedPresentationData) => {
					dispatch(setLoadedPresentation(savedPresentationData))
					toast(t('notifications.success.presentation-saved'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: SUCCESS.toLowerCase(),
						autoClose: 5000
					})
					toggleSaveDropdownVisible(false)
					if (currentModule === ModuleNamesInterface.MY_PRESENTATIONS) {
						dispatch(setMyPresentationsValue('reloadPresentations', true))
					}
				})
			} else {
				toggleSavePresentationModal(true)
			}
			// existing presentation's been updated, so it's not dirty anymore
			dispatch(setLoadedPresentationDirty(false))
		} else {
			toggleSavePresentationModal(true)
		}
	}

	/**
	 *
	 * @description creates a slideshow of the slides currently being selected
	 * in the presentation bar
	 */
	const handlePlayFromSelected = () => {
		const hasLoadingCards = selectedFiles.filter((slide) => slide.isLoading)
		if (hasLoadingCards.length > 0) {
			toggleConfirmPlayModalOpen(true)
		} else {
			const startFrom = selectedFiles.findIndex((file) => file === selected[0])
			dispatch(clearSlideShow())
			dispatch(
				createSlideShow({
					slides: selectedFiles,
					startFrom
				})
			)
		}
	}

	/**
	 *
	 * @description changes the size of the presentation bar
	 * @param {object} event the performed clicked event
	 */
	const handlePesentationBarSizeChange = (event) => {
		event.stopPropagation()
		if (!presentationBarVisible) {
			dispatch(setTempValue('presentationBarVisible', true))
		}
		const newState = presentationBarSize === SM ? LG : SM
		dispatch(setPresentationbarSize(newState))
		togglePresentationBarSize(newState)

		let presenttionBarHeight = 0 // TODO: move this to a const function
		if (newState === SM) {
			presenttionBarHeight = 270
		} else if (newState === LG) {
			presenttionBarHeight = 800
		}

		presentationBarAnimationControls.start(
			animationVariants(presenttionBarHeight)[
				newState === SM ? 'openSm' : 'openLg'
			]
		)
	}

	/**
	 *
	 * @description checks if the loaded presentation name length is >25
	 * if so, strips it, and adds ... a the end of the name and returns it
	 * returns presentaiton name otherwise
	 * @returns {string} the presentation name
	 */
	const createPresentationName = () =>
		loadedPresentation.subject.length > 40
			? `${loadedPresentation.subject.slice(0, 40)}...`
			: loadedPresentation.subject

	/**
	 *
	 * @description closes the confirm modal
	 * filteres out the loading cards
	 * and creates a new presentatoin with the new array
	 */
	const handlePlayAnyway = () => {
		toggleConfirmPlayModalOpen(false)
		const hasLoadingCards = selectedFiles.filter((slide) => slide.isLoading)
		const toPlay = differenceBy(selectedFiles, hasLoadingCards, 'isLoading')
		dispatch(clearSlideShow())
		dispatch(
			createSlideShow({
				slides: toPlay,
				startFrom: 0
			})
		)
	}

	/**
	 *
	 * @description handles sharing presentation with a colleague
	 * if this is not a saved presentation, first toggle the save presentation modal
	 * once the presentation gets saved, we have the response with the API
	 * then opening share with colleague modal
	 * and using the API response from saving to share the presentation
	 */
	const handleShareWithColleague = () => {
		if (!loadedPresentation) {
			toggleIsNewPresentation(true)
			toggleSavePresentationModal(true)
		} else {
			setNewPresentationId(loadedPresentation.id)
			toggleShareWithColleagueModal(true)
			toggleSavePresentationModal(false)
		}
	}
	const getValue = (value) =>
		new Promise((resolve) => {
			resolve(value)
		})

	/**
	 *
	 * @description gets the object from the previously saved presentation
	 * and stores the ID in a local state
	 * @param {number} newPresentationData the id if the previously created presentation
	 */
	const handleCallBack = (newPresentationData) => {
		getValue(newPresentationData).then((data) => {
			setNewPresentationId(data.id)
			toggleShareWithColleagueModal(true)
			toggleSavePresentationModal(false)
		})
	}

	const savePresentationModalProps = {
		isOpen: isSavePresentationModalOpen,
		handleClose: () => toggleSavePresentationModal(false),
		...(isNewPresentation && {
			callback: (newPresentation) => handleCallBack(newPresentation)
		})
	}

	/**
	 * @description generates a PDF document of presentation deck slides
	 */
	const printToPdf = async (event, checkForRestrictedFiles) => {
		event.stopPropagation()
		const restrictedFilesArray = selectedFiles.filter(
			(sf) => !PDF_ALLOWED_TYPES.includes(sf.type.toUpperCase())
		)
		const allowedSlides = selectedFiles.filter((sf) =>
			PDF_ALLOWED_TYPES.includes(sf.type.toUpperCase())
		)
		if (checkForRestrictedFiles && selectedFiles > allowedSlides) {
			setRestrictedFiles(restrictedFilesArray)
			toggleRestrictedFilesModalVisible(true)
		} else {
			toggleShareAsDropwdown(!isShareAsDropdownVisible)
			dispatch(setTempValue('generatePdfWidget', { visibility: true, pdf: '' }))
			const pdfData = {
				extended_fields: {
					summary: allowedSlides
				},
				document: 'presentation',
				userName: loggedInUserName
			}
			createPdfNew(pdfData, authToken).then(() => {
				if (loadedPresentation) {
					dispatch(
						addToAnalyticsBatch({
							event: 'presentation.downloaded',
							uuid: fingerPrint
						})
					)
				}
			})
		}
	}

	/**
	 *
	 * @description clears the presentation in the redux store
	 * to start a new one
	 */
	const createNewPresentation = () => {
		dispatch(clearPresentation())
		dispatch(setLoadedPresentation(undefined))
		toggleNewPresentationConfirmModal(false)
	}

	const checkIfFileIsEditable = (file) => {
		if (file.type && isEditableDocument(file.type)) {
			if (file.status === 11) {
				return true
			}
			if (file.status === 1 && file.editing === 1) {
				return true
			}
		}
		if (file._file) {
			if (isEditableSlide(file._file.type) && file._file.splitted === 1) {
				if (file.status === 11) {
					return true
				}
				if (file.status === 1 && file._file.editing === 1) {
					return true
				}
			}
		}
		return false
	}

	const openCollabora = (file) => {
		const uuid = uuidv4()
		let url
		if (isEditableDocument(file.type)) {
			url = `${getSalesframeAPIUrl()}/wopi/files/${file.checksum}|${
				file.status === 1 ? 0 : file.tags_id
			}|${file.status}|${file.status}|${uuid}|${window.location.host}|v2`
		} else if (isEditableSlide(file._file.type)) {
			url = `${getSalesframeAPIUrl()}/wopi/files/${file._file.checksum}|${
				file.status === 1 ? 0 : file.tags_id
			}|${file._file.status}|${file.status}|${uuid}|${window.location.host}|v2`
		}
		if (url) {
			const collaboraObj = {
				url: `${window.location.origin}/editor/browser/6ba7057/cool.html?WOPISrc=${url}`,
				token: authToken,
				file,
				uuid,
				folderId: file.status === 1 ? 0 : file.tags_id,
				checkAddToPresentation: true,
				hideSaveButton: true,
				source: 'presentation_deck'
			}
			dispatch(setTempValue('collaboraData', collaboraObj))
		}
	}

	const isSavingFile = (file) => {
		const checksum = file._file ? file._file.checksum : file.checksum
		return (
			collaoraSavingFiles.findIndex(
				(item) => item.checksum === checksum && item.fileId === file.id
			) > -1
		)
	}

	return (
		<>
			<MemositeBar
				isVisible={memositeBuilderStatus === 'opened'}
				editingMode={false}
				handleClose={handleOpen}
			/>
			<MemositeBar
				isVisible={memositeBuilderStatus === 'editing'}
				editingMode={true}
				handleClose={() => null}
			/>
			<PresentaitonBarRootContainer
				className={styles.rootContainer}
				zIndex={
					[
						ModuleNamesInterface.STORYBOARD,
						ModuleNamesInterface.LAYOUT
					].includes(currentModule)
						? 9998
						: 999
				}
			>
				<BigGreenButton role={PRESENTATION_BAR.toLowerCase()} />
				<PresentaitonBarInnerContainer
					initial="closed"
					variants={animationVariants}
					animate={presentationBarAnimationControls}
					className={classnames(
						styles.presentationBarHandler,
						layoutSettings.presentation?.presentationDeckShadow
							? styles.hasShadow
							: '',
						!presentationBarVisible ? styles.animateHover : ''
					)}
					presentationDeckFontColor={
						layoutSettings.presentation
							? hexToRgba(
									layoutSettings.presentation.presentationDeckFontColor,
									1
							  )
							: defaultLayout.presentationDeckFontColor
					}
					presentationDeckColor={
						layoutSettings.presentation
							? hexToRgba(layoutSettings.presentation.presentationDeckColor, 1)
							: defaultLayout.presentationDeckColor
					}
				>
					<div
						className={styles.presentationBarHandlerInnerContainer}
						onClick={handleOpen}
						tabIndex={-1}
						onKeyDown={null}
						role="button"
					>
						<div className={styles.leftSide}>
							<p
								className={styles.presentationName}
								title={loadedPresentation?.subject}
							>
								{loadedPresentation
									? createPresentationName()
									: t('labels.presentation')}
								<span className={styles.slideNumber}>
									{selectedFiles.length} <span>{t('labels.slides')}</span>
								</span>
							</p>
						</div>
						<div
							className={styles.openCloseIcon}
							onClick={handleOpen}
							id="presentation-bar-open-close"
						>
							{presentationBarVisible ? (
								<div>
									<ExpandMoreIcon />
									<div>{t('labels.close')}</div>
								</div>
							) : (
								<div>
									<ExpandLessIcon />
									<div>{t('labels.open')}</div>
								</div>
							)}
						</div>
						<div className={styles.rightSideIcons}>
							<ul>
								<li>
									<div className={styles.shareBtnContainer}>
										<StyledActionButton
											presentationDeckFontColor={
												layoutSettings.presentation
													? layoutSettings.presentation
															.presentationDeckFontColor
													: defaultLayout.presentationDeckFontColor
											}
											presentationDeckColor={
												layoutSettings.presentation
													? layoutSettings.presentation.presentationDeckColor
													: defaultLayout.presentationDeckColor
											}
											backgroundColor={
												layoutSettings.presentation
													? layoutSettings.presentation
															.presentationDeckFontColor
													: defaultLayout.presentationDeckFontColor
											}
											className={classnames(styles.customBtn)}
											type="button"
											onClick={(event) => {
												event.stopPropagation()
												presentationBarVisible && handleOpen()
												dispatch(setMemositeBuilderValue('status', 'opened'))
											}}
										>
											<ShareIcon
												htmlColor={
													layoutSettings.presentation
														? hexToRgba(
																layoutSettings.presentation
																	.presentationDeckColor,
																1
														  )
														: defaultLayout.presentationDeckColor
												}
											/>
											{t('buttons.create_memosite')}
										</StyledActionButton>
									</div>
								</li>
								<li>
									<StyledActionButton
										presentationDeckFontColor={
											layoutSettings.presentation
												? layoutSettings.presentation.presentationDeckFontColor
												: defaultLayout.presentationDeckFontColor
										}
										presentationDeckColor={
											layoutSettings.presentation
												? layoutSettings.presentation.presentationDeckColor
												: defaultLayout.presentationDeckColor
										}
										backgroundColor={
											layoutSettings.presentation
												? layoutSettings.presentation.presentationDeckFontColor
												: defaultLayout.presentationDeckFontColor
										}
										type="button"
										tabIndex={-1}
										onClick={(event) => handlePresentationStart(event)}
										disabled={selectedFiles.length === 0}
										className={classnames(
											styles.customBtn,
											selectedFiles.length === 0 && styles.disabled
										)}
									>
										<PlayIcon
											htmlColor={
												layoutSettings.presentation
													? hexToRgba(
															layoutSettings.presentation.presentationDeckColor,
															1
													  )
													: defaultLayout.presentationDeckColor
											}
										/>
										{t('buttons.play')}
									</StyledActionButton>
								</li>
							</ul>
						</div>
					</div>
					<div className={classnames(styles.innerContainer)}>
						<div className={styles.actionBtnContainer}>
							<ul className={styles.actionBtnList}>
								<li className={styles.actionBtnElement}>
									<Button
										type={BUTTON_TEXT}
										containerClass={styles.button}
										buttonClass={styles.oneButton}
										iconSide={ICON_LEFT}
										icon={
											<AddBoxOutlined
												htmlColor={
													layoutSettings.presentation
														? hexToRgba(
																layoutSettings.presentation
																	.presentationDeckFontColor,
																1
														  )
														: defaultLayout.presentationDeckFontColor
												}
											/>
										}
										label={t('labels.new-presentation')}
										onClick={() => toggleNewPresentationConfirmModal(true)}
										id="presentation-bar-new-presentation-button"
									/>
								</li>
								{!loadedPresentation ? (
									<li className={styles.actionBtnElement}>
										<Button
											type={BUTTON_TEXT}
											containerClass={styles.button}
											buttonClass={styles.oneButton}
											iconSide={ICON_LEFT}
											icon={
												<SaveOutlined
													htmlColor={
														layoutSettings.presentation
															? hexToRgba(
																	layoutSettings.presentation
																		.presentationDeckFontColor,
																	1
															  )
															: defaultLayout.presentationDeckFontColor
													}
												/>
											}
											label={t('labels.save')}
											onClick={(event) => handlePresentationSave(event)}
											id="presentation-bar-save-button"
										/>
									</li>
								) : (
									<li
										className={styles.actionBtnElement}
										ref={saveAsDropdownRef}
									>
										<Button
											type={BUTTON_TEXT}
											closeDropDown={() => toggleDropwdown(false)}
											label={t('labels.save')}
											containerClass={styles.button}
											onClick={(event) => {
												event.stopPropagation()
												toggleSaveDropdownVisible(!isSaveDropdownVisible)
											}}
											iconSide={ICON_LEFT}
											isDropdownVisible={isSaveDropdownVisible}
											buttonClass={styles.oneButton}
											dropdownClass={classnames(
												styles.dropdown,
												styles.saveAsDropdown
											)}
											id="presentation-bar-save-as-button"
											iconClass={classnames(styles.toggleButton)}
											icon={
												<SaveOutlined
													htmlColor={
														layoutSettings.presentation
															? hexToRgba(
																	layoutSettings.presentation
																		.presentationDeckFontColor,
																	1
															  )
															: defaultLayout.presentationDeckFontColor
													}
												/>
											}
											dropdownBackground={
												layoutSettings.presentation
													? hexToRgba(
															layoutSettings.presentation.presentationDeckColor,
															1
													  )
													: defaultLayout.presentationDeckColor
											}
											dropdownItems={[
												<span
													onKeyDown={null}
													onClick={(event) =>
														handlePresentationSave(event, true)
													}
													role="button"
													tabIndex={0}
												>
													<SaveOutlinedIcon
														htmlColor={
															layoutSettings.presentation
																? hexToRgba(
																		layoutSettings.presentation
																			.presentationDeckFontColor,
																		1
																  )
																: defaultLayout.presentationDeckFontColor
														}
													/>
													{` ${t('labels.save-presentation')}`}
												</span>,
												<span
													onKeyDown={null}
													onClick={(event) =>
														handlePresentationSave(event, false)
													}
													role="button"
													tabIndex={0}
												>
													<SaveAsOutlinedIcon
														htmlColor={
															layoutSettings.presentation
																? hexToRgba(
																		layoutSettings.presentation
																			.presentationDeckFontColor,
																		1
																  )
																: defaultLayout.presentationDeckFontColor
														}
													/>
													{` ${t('buttons.save_as_new')}`}
												</span>
											]}
										/>
									</li>
								)}
								<li
									{...getRootProps()}
									className={styles.actionBtnElement}
									ref={shareDropdownRef}
								>
									{currentFiles.length > 0 && <input {...getInputProps()} />}
									<Button
										type={BUTTON_TEXT}
										closeDropDown={() => toggleDropwdown(false)}
										label={t('labels.share')}
										containerClass={styles.button}
										onClick={(event) => handleShareAsDropDown(event)}
										iconSide={ICON_LEFT}
										isDropdownVisible={isShareAsDropdownVisible}
										buttonClass={styles.oneButton}
										dropdownClass={styles.dropdown}
										iconClass={classnames(styles.toggleButton)}
										isDisabled={currentFiles.length === 0}
										id="presentation-bar-share-button"
										icon={
											<ShareIcon
												htmlColor={
													layoutSettings.presentation
														? hexToRgba(
																layoutSettings.presentation
																	.presentationDeckFontColor,
																1
														  )
														: defaultLayout.presentationDeckFontColor
												}
											/>
										}
										dropdownBackground={
											layoutSettings.presentation
												? hexToRgba(
														layoutSettings.presentation.presentationDeckColor,
														1
												  )
												: defaultLayout.presentationDeckColor
										}
										dropdownItems={[
											<span
												className={styles.item}
												onKeyDown={null}
												onClick={handleShareWithColleague}
												role="button"
												tabIndex={0}
											>
												<GroupOutlinedIcon
													htmlColor={
														layoutSettings.presentation
															? hexToRgba(
																	layoutSettings.presentation
																		.presentationDeckFontColor,
																	1
															  )
															: defaultLayout.presentationDeckFontColor
													}
												/>{' '}
												{t('buttons.send_to_colleagues')}
											</span>,
											printToPdfEnabled !== 0 && (
												<span
													onKeyDown={null}
													onClick={(e) => printToPdf(e, true)}
													role="button"
													tabIndex={0}
													className={styles.item}
												>
													<PictureAsPDFOutlinedIcon
														htmlColor={
															layoutSettings.presentation
																? hexToRgba(
																		layoutSettings.presentation
																			.presentationDeckFontColor,
																		1
																  )
																: defaultLayout.presentationDeckFontColor
														}
													/>{' '}
													{t('labels.print-as-pdf')}
												</span>
											)
										]}
									/>
								</li>
								{riskMeterCustomers.includes(customerId) && (
									<li className={styles.actionBtnElement}>
										<Button
											type={BUTTON_TEXT}
											containerClass={styles.button}
											buttonClass={styles.oneButton}
											iconSide={ICON_LEFT}
											icon={
												<RiskMeterIcon
													htmlColor={
														layoutSettings.presentation
															? hexToRgba(
																	layoutSettings.presentation
																		.presentationDeckFontColor,
																	1
															  )
															: defaultLayout.presentationDeckFontColor
													}
												/>
											}
											id="presentation-bar-risk-meter-button"
											label={t('buttons.risk-meter')}
											onClick={() => toggleRiskMeterModal(true)}
										/>
									</li>
								)}
								<li
									className={classnames(
										currentFiles.length === 0 ? styles.hidden : '',
										styles.actionBtnElement
									)}
								>
									<Button
										type={BUTTON_TEXT}
										containerClass={styles.button}
										buttonClass={styles.oneButton}
										iconSide={ICON_LEFT}
										icon={
											<DeleteIcon
												htmlColor={
													layoutSettings.presentation
														? hexToRgba(
																layoutSettings.presentation
																	.presentationDeckFontColor,
																1
														  )
														: defaultLayout.presentationDeckFontColor
												}
											/>
										}
										id="presentation-bar-delete-button"
										label={
											selected.length === currentFiles.length ||
											selected.length === 0
												? t('buttons.remove_all')
												: t('buttons.remove')
										}
										onClick={() =>
											handleDeleteFromPresentationBar(
												selected.length === currentFiles.length ||
													selected.length === 0
											)
										}
									/>
								</li>
								<li className={styles.actionBtnElement}>
									<Button
										type={BUTTON_TEXT}
										containerClass={styles.button}
										buttonClass={styles.oneButton}
										iconSide={ICON_LEFT}
										icon={
											<PlayCircleOutlinedIcon
												htmlColor={
													layoutSettings.presentation
														? hexToRgba(
																layoutSettings.presentation
																	.presentationDeckFontColor,
																1
														  )
														: defaultLayout.presentationDeckFontColor
												}
											/>
										}
										label={t('buttons.play_from')}
										onClick={() => handlePlayFromSelected()}
										isDisabled={selected.length === 0}
										id="presentation-bar-play-from-button"
									/>
								</li>
							</ul>
							<div className={styles.actionBtnList}>
								<ul>
									<li>
										<Button
											type={BUTTON_TEXT}
											iconSide={ICON_LEFT}
											icon={
												<InfoIcon
													htmlColor={
														layoutSettings.presentation
															? hexToRgba(
																	layoutSettings.presentation
																		.presentationDeckFontColor,
																	1
															  )
															: defaultLayout.presentationDeckFontColor
													}
												/>
											}
											onClick={() => null}
											id="presentation-bar-featured-guide-button"
											tooltip={t('tooltips.quick_guide_for', [
												t('labels.presentation_deck')
											])}
										/>
									</li>
									<li>
										<StyledIconButton
											onClick={(event) => handlePesentationBarSizeChange(event)}
											presentationDeckFontColor={
												layoutSettings.presentation
													? layoutSettings.presentation
															.presentationDeckFontColor
													: defaultLayout.presentationDeckFontColor
											}
											variants={presentationBarSizeButtonVariants}
											animate={presentationBarSize === LG ? 'lg' : 'sm'}
											whileHover={{
												scale: 1.2,
												transition: {
													duration: 0.1
												}
											}}
											type="button"
											className={classnames(styles.extendBtn)}
											id="presentation-bar-extend-button"
										>
											<LastPageIcon />
										</StyledIconButton>
									</li>
									<li>
										{!isIOS() && !isSafari() && (
											<StyledIconButton
												onClick={handleFullScreen}
												presentationDeckFontColor={
													layoutSettings.presentation
														? layoutSettings.presentation
																.presentationDeckFontColor
														: defaultLayout.presentationDeckFontColor
												}
												whileHover={{
													scale: 1.2,
													transition: {
														duration: 0.1
													}
												}}
												type="button"
												className={classnames(styles.fullScreenBtn)}
												id="presentation-bar-fullscreen-button"
											>
												<FullscreenIcon />
											</StyledIconButton>
										)}
									</li>
								</ul>
							</div>
						</div>
						<CardContainer
							minMaxWidth={130}
							className={classnames(
								styles.cardContainer,
								currentPresentationBarSize === LG
									? styles.largeContainer
									: styles.smallContainer
							)}
							ref={horizontalScrollRef}
							onClick={(e) => {
								e.stopPropagation()
							}}
						>
							{showLeftArrow && (
								<ScrollStepWrapper
									className={classnames(styles.scrollStep, styles.back)}
									backgroundColor={
										layoutSettings.presentation
											? layoutSettings.presentation.presentationDeckColor
											: defaultLayout.presentationDeckColor
									}
									fontColor={
										layoutSettings.presentation
											? layoutSettings.presentation.presentationDeckFontColor
											: defaultLayout.presentationDeckFontColor
									}
									gradientDirection="to right"
									onClick={() => scrollStepHandler(-500)}
								>
									<StepBackIcon />
								</ScrollStepWrapper>
							)}
							{showRightArrow && (
								<ScrollStepWrapper
									className={classnames(styles.scrollStep, styles.forward)}
									backgroundColor={
										layoutSettings.presentation
											? layoutSettings.presentation.presentationDeckColor
											: defaultLayout.presentationDeckColor
									}
									fontColor={
										layoutSettings.presentation
											? layoutSettings.presentation.presentationDeckFontColor
											: defaultLayout.presentationDeckFontColor
									}
									gradientDirection="to left"
									onClick={() => scrollStepHandler(500)}
								>
									<StepForwardIcon />
								</ScrollStepWrapper>
							)}
							{currentPresentationBarSize === SM && (
								<UploadCard
									toggleUploadManagerModalVisible={() =>
										dispatch(
											setTempValue('uploadManagerModal', {
												isShowing: true,
												role: 'presentation_bar',
												modalType: 'modal'
											})
										)
									}
									onDrop={onDrop}
									useDeckFontColor
									usedFromDeck
									role="presentation-deck"
								/>
							)}
							<SortableWrapper
								axis="xy"
								distance={20}
								onSortEnd={onSortEnd}
								transitionDuration={400}
								updateBeforeSortStart={(node) => {
									setDraggingCardIndex(node.index)
									selected.length > 1 && toggleIsDragging(true)
								}}
								divStyle={divStyle}
								lockToContainerEdges
							>
								{currentPresentationBarSize === LG && (
									<UploadCard
										toggleUploadManagerModalVisible={() =>
											dispatch(
												setTempValue('uploadManagerModal', {
													isShowing: true,
													role: 'presentation_bar',
													modalType: 'modal'
												})
											)
										}
										onDrop={onDrop}
										useDeckFontColor
										role="presentation-deck"
									/>
								)}
								{currentFiles.map((file, index) => (
									<SortableItem
										/*key={`item-${file.key}`}*/
										key={`item-${file.key || `${file.id}-${file.tags_id}`}`}
										index={index}
										value={file.id}
									>
										<Card
											index={index}
											draggingFiles={
												isDragging &&
												index === draggingCardIndex &&
												selected.length
											}
											hasToolbar={!isDropdownVisibe}
											className={
												selected.includes(file) &&
												isDragging &&
												styles.draggingCard
											}
											isSelected={selected.includes(file)}
											isToolbarVisible={selected[selected.length - 1] === file}
											handleCardClick={
												file.isLoading
													? () => {}
													: (event) => handleSelect(file, event)
											}
											isLoadingCard={
												file.isLoading || isSavingFile(file) || false
											}
											isConverting={
												conversionFiles.findIndex(
													(item) =>
														item.checksum === file.checksum &&
														item.sortorder === file.sortorder
												) > -1
											}
											cardObject={{
												...file,
												extension: file.type,
												id: file.id
											}}
											hasLayoutFontColor={
												layoutSettings.presentation &&
												layoutSettings.presentation.presentationDeckFontColor
											}
											isSplitting={
												splitFilesList.findIndex(
													(splitFile) => splitFile.checksum === file.checksum
												) > -1
											}
											isEditable={checkIfFileIsEditable(file)}
											openFileEditor={openCollabora}
											role={PRESENTATION_BAR.toLowerCase()}
										/>
									</SortableItem>
								))}
							</SortableWrapper>
						</CardContainer>
					</div>
				</PresentaitonBarInnerContainer>
				<SavePresentationModal {...savePresentationModalProps} />
				<ShareWithColleagueModal
					isVisible={isShareWithColleagueModalVisible}
					handleClose={() => {
						toggleShareWithColleagueModal(false)
					}}
					presentationId={newPresentationId}
				/>
				<ConfirmDeleteModal
					onApprove={() => handlePlayAnyway()}
					handleClose={() => toggleConfirmPlayModalOpen(false)}
					isActive={isConfirmPlayModalOpen}
					confirmLabel={t('buttons.play-anyway')}
				>
					<Section padding="0 20px 20px" title="">
						<p className={styles.playAnywayAlert}>
							{t('misc.play-anyway-content')}
						</p>
					</Section>
				</ConfirmDeleteModal>
				{riskMeterCustomers.includes(customerId) && (
					<RiskMeterModal
						isShowing={riskMeterModal}
						handleClose={() => {
							toggleRiskMeterModal(false)
						}}
					/>
				)}
			</PresentaitonBarRootContainer>
			<CustomSlides
				isShowing={openCustomSlides}
				handleClose={() => toggleOpenCustomSlides(false)}
			/>
			<RestrictedFilesInPdfModal
				action={(e) => {
					toggleRestrictedFilesModalVisible(false)
					setRestrictedFiles([])
					printToPdf(e, false)
				}}
				isShowing={restrictedFilesModalVisible}
				closeModal={() => {
					toggleRestrictedFilesModalVisible(false)
					setRestrictedFiles([])
				}}
				filesRestricted={restrictedFiles}
			/>
			<ConfirmDeleteModal
				isActive={isNewPresentationConfirmVisible}
				handleClose={() => toggleNewPresentationConfirmModal(false)}
				onApprove={() => createNewPresentation()}
				title={t('titles.are-you-sure')}
				confirmLabel={t('buttons.confirm')}
				cancelLabel={t('buttons.cancel')}
			>
				<Section
					sectionClassName={styles.confirmNewPresentation}
					justify={FLEX_START}
				>
					<p>{t('titles.create-new-presentation')}</p>
				</Section>
			</ConfirmDeleteModal>
		</>
	)
}

PresentationBar.defaultProps = {
	autoCloseContentPane: false,
	closeContentPane: () => {}
}

PresentationBar.propTypes = {
	autoCloseContentPane: PropTypes.bool,
	closeContentPane: PropTypes.func
}

export default PresentationBar
